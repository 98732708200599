import React, { Component } from 'react';
import NavigationMenuLink from '../../../../../components/Navigation/NavigationMenu/NavigationMenuLink';
import Loading from '../../../../Loading/Loading';
import config from '../../../../../config';

export default class SubscribeToPro extends Component<
    {
        // props
        renderContent: boolean;
    },
    {
        // state
    }
> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <React.Suspense fallback={<Loading />}>
                <div className="mobile-page-section__subscribe">
                    <div className="request-a-quote">
                        <div className="mobile-page-section__title -center -large">Powerful data and analysis on nearly every digital topic.</div>
                        <a
                            href={`${config.emarketerServiceUrl}corporate/request-quote-open-access`}
                            data-analytics="Branding-Navigation-Subscribe-RequestQuote"
                        >
                            <button>Request a Quote</button>
                        </a>
                    </div>
                    <NavigationMenuLink menuName="SubscribePRO-Mobile" text={'See all contact info'} url={`${config.emarketerServiceUrl}about/contactus`} />
                    <div className="mobile-page-section__hr-mini" />
                    <div className="mobile-page-section__title">Subscribe to EMARKETER</div>
                    <ul className="mobile-page-section__list -subscribe">
                        <NavigationMenuLink
                            menuName="SubscribePRO-Mobile"
                            text={'Plans & Pricing'}
                            url={`${config.emarketerServiceUrl}corporate/products-plans`}
                        />
                        <NavigationMenuLink
                            menuName="SubscribePRO-Mobile"
                            text={'Process'}
                            url={`${config.emarketerServiceUrl}corporate/why-emarketer#tab=OurApproach`}
                        />
                        <NavigationMenuLink
                            menuName="SubscribePRO-Mobile"
                            text={'Why EMARKETER?'}
                            url={`${config.emarketerServiceUrl}corporate/why-emarketer`}
                        />
                        <NavigationMenuLink menuName="SubscribePRO-Mobile" text={'Coverage'} url={`${config.emarketerServiceUrl}corporate/pro`} />
                        <NavigationMenuLink menuName="SubscribePRO-Mobile" text={'Customer Stories'} url={`${config.emarketerServiceUrl}corporate/clients`} />
                    </ul>
                </div>
            </React.Suspense>
        );
    }
}
