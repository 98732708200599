import React, { Component } from 'react';
import FormatText from '../../../helpers/FormatText';

export default class NavigationMenuLink extends Component<{
// props
    menuName: string;
    text: string;
    url: string;
}, {
// state
}> {

    public render() {

        const { menuName, text, url } = this.props;

        return (
            <li><a href={url} data-analytics={`Branding-Navigation-${FormatText(menuName)}-${FormatText(text)}`}>{text}</a></li>
        );
    }
}
