import React, { Component } from 'react';
import './Menu.scss';
import config from 'config';

/* tslint:disable */

interface IMenuProps {
    active: boolean;
}

export default class Menu extends Component<IMenuProps, {}> {

    constructor(props) {
        super(props);
    }

    public render() {

        const isMenuActive = this.props.active ? ' -active' : '';

        return (
            <div className={`user-promo-menu${isMenuActive}`}>
                <img className="user-promo-menu__arrow" alt={'arrow'} src={require('./images/menu-arrow-red.png')}/>
                <div className="user-promo-menu__modal">
                    <div className="user-promo-menu__modal-title">L A T E S T</div>
                    <div className="user-promo-menu__modal-content">
                        How is AI transforming Advertising & Marketing?
                    </div>
                </div>
            </div>
        );
    }
}