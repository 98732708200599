import React, { Component } from 'react';
import config from 'config';
import LoadRemoteComponent from '../../components/LoadRemoteComponent/LoadRemoteComponent';
import './FreemiumOnboarding.scss';

export default class FreemiumOnboarding extends Component<{userId: string}, {}> {

  public componentDidMount() {
    // Disable scrolling on the body when the component mounts
    document.body.style.overflow = 'hidden';
  }

  public componentWillUnmount() {
    // Re-enable scrolling on the body when the component unmounts
    document.body.style.overflow = 'auto';
  }

  public render() {

    const onboardingProps = {
      baseUrl: config.emarketerBaseUrl,
      loginUrl: config.subscriptionsServiceUrl,
      subscriptionApiUrl: config.subscriptionsServiceUrl,
      preferenceApiUrl: config.contentPersonalizationAPI,
      placement: '',
      analyticsPrefix: 'PRO',
      freemiumExtranetId: config.freemiumExtranetId,
      onContinue: () => {
        window.location.href =  window.location.href;
      },
      userId: this.props.userId,
    };

    return (
      <div className="freemium_modal_wrapper">
        <div className="freemium_onboarding_component_wrapper" >

          <LoadRemoteComponent
            url={config.freemiumUserRegistrationLibraryUrl}
            name="UserRegistration"
            props={onboardingProps}/>

          </div>
      </div>

      );
  }
}
