import AuthService from './AuthService';
import PodService from './PodService';
import config from '../config';
import Cookies from 'js-cookie';

import JwtReader from 'em-jwt-reader';

interface ISubscriptionResult {
    redirected: boolean;
    accessToken: IAccessTokenData;
    referAColleague: boolean;
    hasFreemiumRole: boolean;
}

const redirectResult: ISubscriptionResult = { redirected: true, accessToken: null, referAColleague: false, hasFreemiumRole: false};

class Subscriptions {
    private enablePodRedirection: boolean;

    public Init(enablePodRedirection: boolean): ISubscriptionResult {
        const url = window.location.href.toLowerCase();

        const authenticateRedirectUrl = AuthService.Authenticate(document.cookie.indexOf('em_at=') >= 0, enablePodRedirection, config.pod);
        this.enablePodRedirection = enablePodRedirection;

        if (authenticateRedirectUrl) {
            window.location.href = authenticateRedirectUrl;
            return redirectResult;
        }

        // this call is a replacement for fetch branding/me, subscriptions/me, and subscriptions/authentication
        const accessToken = JwtReader.ReadAccessToken(Cookies.get('em_at'));
        const referAColleague = JwtReader.IsInRole(Cookies.get('em_at'), 'refer_a_colleague');
        const hasFreemiumRole = JwtReader.IsInRole(Cookies.get('em_at'), 'em_freemium');
        const redirectUrl = PodService.DoPodRedirect(enablePodRedirection, accessToken.authenticated, config.pod, url, accessToken.pod);
        if (redirectUrl) {
            window.location.href = redirectUrl;
            return redirectResult;
        }

        if (accessToken.authenticated) {
          this.InitTokenRefresh(accessToken.expires, false);
        } else if (Cookies.get('em_rte')) {
          // if refresh token exists (rte)
          this.TokenRefreshInternal();
        }

        return { redirected: false, accessToken, referAColleague, hasFreemiumRole };
    }

    private InitTokenRefresh(expTs: number, justIssued: boolean) {
        const currentTimeMs = new Date().getTime();
        const expTimeMs =  expTs * 1000;
        const remainingMs = expTimeMs - currentTimeMs;
        const maxTokenRemainingLifeInMs = config.maxTokenRemainingLifeInMinutes * 60 * 1000;

        if (remainingMs <= maxTokenRemainingLifeInMs && !justIssued) {
            this.TokenRefreshInternal();
            return;
        }

        const msUntilThirtyMinutesBeforeCutoff = remainingMs - maxTokenRemainingLifeInMs;

        if (msUntilThirtyMinutesBeforeCutoff >= 0) {
            setTimeout(this.TokenRefreshInternal.bind(this), msUntilThirtyMinutesBeforeCutoff);
        }
    }

    private TokenRefreshInternal() {
        fetch(`${config.subscriptionsServiceUrl}/RefreshToken`,
        {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
        }).then((response) => {
            if (response.ok) {
                const url = window.location.href.toLowerCase();
                const accessToken = JwtReader.ReadAccessToken(Cookies.get('em_at'));
                const redirectUrl = PodService.DoPodRedirect(this.enablePodRedirection, accessToken.authenticated, config.pod, url, accessToken.pod);

                if (redirectUrl) {
                    window.location.href = redirectUrl;
                    return;
                }

                this.InitTokenRefresh(accessToken.expires, true);
            }
        });
    }
}

export default new Subscriptions();
