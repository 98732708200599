import React, { Component } from 'react';
import Logo from '../../Logo/Logo';
import './Page.scss';

export default class Page extends Component<{
    // props
    direction: string;
    active: boolean;
    otherPageActive: boolean;
    closeFn: any;
    isTransparent: boolean;
    authenticated: boolean;
    pod: string;
}, {
    // state
}> {
    public render() {

    const { active, otherPageActive, direction, closeFn, isTransparent, authenticated, pod } = this.props;

    const pageStyles = () => {
        const isA = active ? ' -active' : '';
        const isH = otherPageActive ? ' -hidden' : '';
        let transitionD;
        switch (direction) {
            case 'left': transitionD = ' -left'; break;
            case 'right': transitionD = ' -right'; break;
            default: transitionD = ' -right'; break;
        }
        return `mobile-page${isA}${isH}${transitionD}`;
    };

    return (
            <div className={pageStyles()}>
                <Logo isTransparent={false} authenticated={authenticated} pod={pod} />
                <div className="mobile-page__content">
                    {this.props.children}
                </div>
                {
                    active ?
                    <div className="mobile-page__close" data-analytics="Branding-Mobile-Navigation-Close" onClick={closeFn}><span className={`icon-cross`} /></div>
                    : null
                }
            </div>
        );
    }
}
