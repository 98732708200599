import React, { Component } from 'react';
import Hamburger from '../MobileMenu/Hamburger/Hamburger';
import IconButton from './IconButton/IconButton';
import User from '../../theme/svgs/user';
import Logo from '../Logo/Logo';
import Page from './Page/Page';
import NavigationPage from './Page/PageTemplates/NavigationPage';
import UserMenuPage from './Page/PageTemplates/UserMenuPage';
import CalendarPage from './Page/PageTemplates/CalendarPage';
import BuildServiceUrl from '../../helpers/ServiceUrlBuilder';
import './MobileMenu.scss';

export default class MobileMenu extends Component<
    {
        // props
        authenticated: boolean;
        isIpUser: boolean;
        isTransparent: boolean;
        pod: string;
        enablePodRedirection?: boolean;
        returnToCurrentServiceAfterRegistration?: boolean;
        returnUrlQueryString?: string;
        registrationSource?: string;
        userEmail: string;
        corporate?: boolean;
        enableIndustryKPIs?: boolean;
        hasFreemiumRole: boolean;
    },
    {
        // state
        navOpen: boolean;
        userOpen: boolean;
        calOpen: boolean;
        navWrapperRef: any;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            navOpen: false,
            userOpen: false,
            calOpen: false,
            navWrapperRef: '',
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleUser = this.toggleUser.bind(this);
        this.toggleCal = this.toggleCal.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setNavWrapperRef = this.setNavWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setNavWrapperRef(node) {
        this.setState({
            navWrapperRef: node,
        });
    }

    private toggleNav() {
        this.setState({
            navOpen: !this.state.navOpen,
            userOpen: false,
            calOpen: false,
        });
        document.getElementsByTagName('body')[0].style.position = 'fixed';
        document.getElementsByTagName('body')[0].style.width = '100%';
    }

    private toggleUser() {
        if (this.props.corporate && this.props.authenticated) {
            window.location.href = BuildServiceUrl(this.props.pod, 'pro');
            return;
        }
        this.setState({
            userOpen: !this.state.userOpen,
            navOpen: false,
            calOpen: false,
        });
        document.getElementsByTagName('body')[0].style.position = 'fixed';
        document.getElementsByTagName('body')[0].style.width = '100%';
    }

    private toggleCal() {
        this.setState({
            calOpen: !this.state.calOpen,
            userOpen: false,
            navOpen: false,
        });
        document.getElementsByTagName('body')[0].style.position = 'fixed';
        document.getElementsByTagName('body')[0].style.width = '100%';
    }

    private closeMenu() {
        this.setState({
            navOpen: false,
            userOpen: false,
            calOpen: false,
        });
        document.getElementsByTagName('body')[0].style.position = null;
        document.getElementsByTagName('body')[0].style.width = null;
    }

    private handleClickOutside(event) {
        if (this.state.navOpen || this.state.userOpen) {
            if (this.state.navWrapperRef && !this.state.navWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {
        const {
            isTransparent,
            authenticated,
            isIpUser,
            pod,
            enablePodRedirection,
            userEmail,
            returnToCurrentServiceAfterRegistration,
            returnUrlQueryString,
            registrationSource,
            enableIndustryKPIs,
            hasFreemiumRole,
        } = this.props;
        const isPageOpen = this.state.navOpen || this.state.userOpen || this.state.calOpen ? true : false;

        const menuStyles = () => {
            const isA = this.state.navOpen || this.state.userOpen || this.state.calOpen ? ' -active' : '';
            const isT = isTransparent ? ' -transparent' : '';
            return `branding-mobile-menu${isA}${isT}`;
        };

        const otherPageActive = this.state.userOpen || this.state.navOpen || this.state.calOpen;
        return (
            <div className={menuStyles()} ref={this.setNavWrapperRef}>
                <div className="branding-mobile-menu__nav-toggle" onClick={this.toggleNav} data-analytics="Branding-Mobile-Navigation-Toggle">
                    <Hamburger isTransparent={isPageOpen ? false : isTransparent} isActive={false} />
                </div>
                <Logo isTransparent={isTransparent} authenticated={authenticated} pod={pod} />
                <div className="branding-mobile-menu__user-toggle" onClick={this.toggleUser} data-analytics="Branding-Mobile-UserMenu-Toggle">
                    <IconButton icon={<User />} isTransparent={isPageOpen ? false : isTransparent} />
                </div>
                <Page
                    active={this.state.navOpen}
                    direction="right"
                    closeFn={this.closeMenu}
                    otherPageActive={otherPageActive}
                    authenticated={authenticated}
                    isTransparent={isTransparent}
                    pod={pod}
                >
                    <NavigationPage authenticated={authenticated} enablePodRedirection={enablePodRedirection} pod={pod} navOpen={this.state.navOpen}
                        enableIndustryKPIs={enableIndustryKPIs} hasFreemiumRole={hasFreemiumRole} />
                </Page>
                <Page
                    active={this.state.userOpen}
                    direction="left"
                    closeFn={this.closeMenu}
                    otherPageActive={otherPageActive}
                    authenticated={authenticated}
                    isTransparent={isTransparent}
                    pod={pod}
                >
                    <UserMenuPage
                        authenticated={authenticated}
                        isIpUser={isIpUser}
                        pod={pod}
                        enablePodRedirection={enablePodRedirection}
                        returnToCurrentServiceAfterRegistration={returnToCurrentServiceAfterRegistration}
                        returnUrlQueryString={returnUrlQueryString}
                        registrationSource={registrationSource}
                        userEmail={userEmail}
                        menuOpen={this.state.userOpen}
                        openCalMenuFn={this.toggleCal}
                    />
                </Page>
                <Page
                    active={this.state.calOpen}
                    direction="left"
                    closeFn={this.closeMenu}
                    otherPageActive={otherPageActive}
                    authenticated={authenticated}
                    isTransparent={isTransparent}
                    pod={pod}
                >
                    <CalendarPage menuOpen={this.state.calOpen} />
                </Page>
            </div>
        );
    }
}
