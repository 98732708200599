import React, { Component } from 'react';
import BuildServiceUrl from '../../helpers/ServiceUrlBuilder';
import config from 'config';
import './Logo.scss';

export default class Logo extends Component<{
    // props
    isTransparent: boolean;
    authenticated: boolean;
    pod: string;
    scrolled?: boolean;
}, {}> {

    public render() {
        const { isTransparent, authenticated, pod, scrolled } = this.props;

        const logo = () => {
            let logoWidth = '200';
            let logoHeight = '40';
            if (typeof window !== 'undefined' && window.innerWidth < 1150) {
                logoWidth = '200';
                logoHeight = '40';
            }
            return isTransparent ? (
                    <img src={`${config.brandingServiceUrl}/app/6.0/build/${require('./images/emarketer-logo-white.svg')}`} alt="EMARKETER" width={logoWidth} height={logoHeight} />
                ) : (
                    <img src={`${config.brandingServiceUrl}/app/6.0/build/${require('./images/emarketer-logo-black.svg')}`} alt="EMARKETER" width={logoWidth} height={logoHeight} />
                );
        };

        const proUrl = BuildServiceUrl(pod, 'pro');

        const logoStyles = () => {
            const isS = scrolled ? ` -scrolled` : '';
            return `branding__logo${isS}`;
        };

        return (
            <div className={logoStyles()}>
                <a data-analytics={`Branding-Logo-Home`} href={proUrl}>{logo()}</a>
            </div>
        );
    }
}
