import React, { Component } from 'react';
import './NavigationLink.scss';

export default class NavigationLink extends Component<
    {
        // props
        text: string;
        isTransparent: boolean;
        url: string;
        target?: string;
        featured?: boolean;
        scrolled: boolean;
        hideNarrow?: boolean;
    },
    {
        // state
    }
> {
    public render() {
        const { text, isTransparent, url, target, featured, scrolled, hideNarrow } = this.props;

        const styles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ` -scrolled` : '';
            const isHN = hideNarrow ? ` -hideNarrow` : '';
            const isF = featured ? ` -new` : '';
            return `navigation-link${isT}${isS}${isHN}${isF}`;
        };

        return (
            <a className={styles()} href={url} target={target ? target : undefined} data-analytics={`Branding-Navigation-${text}`}>
                {text}
            </a>
        );
    }
}
