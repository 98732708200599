import React, { Component } from 'react';
import Loading from '../Loading/Loading';
import BuildServiceUrl from '../../helpers/ServiceUrlBuilder';
import './UserLogin.scss';

// don't load menu until user interacts with it
const Menu = React.lazy(() =>
  import('./Menu/Menu'),
);

export default class UserLogin extends Component<{
    // props
    isTransparent: boolean;
    pod: string;
    corporate: boolean;
    authenticated: boolean;
    enablePodRedirection: boolean;
    returnToCurrentServiceAfterRegistration: boolean;
    returnUrlQueryString: string;
    registrationSource: string;
    scrolled: boolean;
}, {
    // state
    open: boolean;
    loginWrapperRef: any;
}> {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loginWrapperRef: null,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setLoginWrapperRef = this.setLoginWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setLoginWrapperRef(node) {
        this.setState({
            loginWrapperRef: node,
        });
    }

    private toggleMenu() {
        if (this.props.corporate && this.props.authenticated) {
            window.location.href = BuildServiceUrl(this.props.pod, 'pro');
            return;
        }
        this.setState({
            open: !this.state.open,
        });
    }

    private closeMenu() {
        this.setState({ open: false });
    }

    private handleClickOutside(event) {
        if (this.state.open) {
            if (this.state.loginWrapperRef && !this.state.loginWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {

        const { isTransparent, scrolled } = this.props;

        const buttonStyles = () => {
            const isS = scrolled ? ` -scrolled` : '';
            const isT = isTransparent ? ' -transparent' : '';
            return `user-login__button${isT}${isS}`;
        };

        const userLoginStyles = () => {
            const isS = scrolled ? ` -scrolled` : '';
            return `user-login${isS}`;
        };

        return (
            <div className={userLoginStyles()} ref={this.setLoginWrapperRef}>
                <div className="user-login__divider" />
                <div className={buttonStyles()}
                     onClick={this.toggleMenu}
                     data-analytics="Branding-Tools-Login">Log in</div>
                {
                    this.state.open ?
                    <React.Suspense fallback={<Loading />}>
                        <Menu
                        active={this.state.open}
                        title={''}
                        enablePodRedirection={this.props.enablePodRedirection}
                        returnToCurrentServiceAfterRegistration={this.props.returnToCurrentServiceAfterRegistration}
                        returnUrlQueryString={this.props.returnUrlQueryString}
                        registrationSource={this.props.registrationSource} />
                    </React.Suspense> : null
                }
            </div>
        );
    }
}
