import config from '../config';

export default function BuildServiceUrl(pod, service) {

    const domainUrl = config.domainName;

    function getProServiceUrl(pod) {
        let proUrl = '';
        switch ((pod || '').toLowerCase()) {
            case 'na1': proUrl = `https://pro-na1${domainUrl}/`; break;
            default   : proUrl = `https://www${domainUrl}/`;
        }
        return proUrl;
    }

    function getBuildServiceUrl(pod) {
        let BldUrl = '';
        switch ((pod || '').toLowerCase()) {
            case 'na1': BldUrl = `https://www${domainUrl}/build`; break;
            default   : BldUrl = `https://www${domainUrl}/build`;
        }
        return BldUrl;
    }

    function getGenericServiceUrl(pod, service) {
        let genericServiceUrl = '';
        switch ((pod || '').toLowerCase()) {
            case 'na1': genericServiceUrl = `https://${service}-na1${domainUrl}`; break;
            default   : genericServiceUrl = `https://www${domainUrl}/${service}`;
        }
        return genericServiceUrl;
    }

    function getReportsServiceUrl(pod) {
        let reportServiceUrl = '';
        switch ((pod || '').toLowerCase()) {
            case 'na1': reportServiceUrl = `https://pro-na1${domainUrl}/reports`; break;
            default   : reportServiceUrl = `https://www${domainUrl}/search1/?query=%20&refinementList%5Btype%5D=report`;
        }
        return reportServiceUrl;
    }

    let serviceUrl = '';

    switch (service) {
        case 'pro'        : serviceUrl = getProServiceUrl(pod); break;
        case 'reports'    : serviceUrl = getReportsServiceUrl(pod); break;
        case 'topics'     : serviceUrl = getGenericServiceUrl(pod, 'topics'); break;
        case 'articles'   : serviceUrl = getGenericServiceUrl(pod, 'articles'); break;
        case 'forecasts'  : serviceUrl = getGenericServiceUrl(pod, 'forecasts'); break;
        case 'premium-forecasts'  : serviceUrl = `https://forecasts-premium-subscription${domainUrl}`; break;
        case 'performance': serviceUrl = getGenericServiceUrl(pod, 'performance'); break;
        case 'industry-kpis': serviceUrl = getGenericServiceUrl(pod, 'industry-kpis'); break;
        case 'search1'    : serviceUrl = getGenericServiceUrl(pod, 'search1'); break;
        case 'answers'    : serviceUrl = getGenericServiceUrl(pod, 'answers'); break;
        case 'build'      : serviceUrl = getBuildServiceUrl(pod); break;
        default           : serviceUrl = getProServiceUrl(pod); break;
    }

    return serviceUrl;
}
