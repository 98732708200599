import React, { Component } from 'react';
import NavigationMenuLink from '../NavigationMenuLink';
import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import config from '../../../../config';

export default class Free extends Component<
{
    // props
    pod: string,
}, {
    // state
}> {

    constructor(props: any) {
        super(props);
    }

    public render() {

        const { pod } = this.props;

        return (
            <div className="navigation-menu__free">
                <ul className="navigation-menu__links">
                    <li><h3>Daily News</h3></li>
                    <NavigationMenuLink menuName="Free" text={'Articles'} url={BuildServiceUrl(pod, 'articles')} />
                    <NavigationMenuLink menuName="Free" text={'Podcasts'} url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-podcast`} />
                    <NavigationMenuLink menuName="Free" text={'Roundups'} url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-roundup`} />
                    <NavigationMenuLink menuName="Free" text={'Videos'} url={`${BuildServiceUrl(pod, 'articles')}/videos`} />
                    <NavigationMenuLink menuName="Free" text={'Webinars'} url={`${config.emarketerBaseUrl}/events/`} />
                </ul>
            </div>
        );
    }
}
