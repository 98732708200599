import React, { Component } from 'react';
import MenuRow from './MenuRow/MenuRow';
import config from '../../../config';
import './Menu.scss';

interface IMenuProps {
    active: boolean;
    isIpUser: boolean;
    enablePodRedirection: boolean;
    userEmail: string;
    canReferAColleague: boolean;
}

export default class Menu extends Component<IMenuProps, {}> {

    constructor(props) {
        super(props);
    }

    public render() {

        const { enablePodRedirection, userEmail, isIpUser, canReferAColleague } = this.props;

        const currentUrl = window.location.href;
        const emarketerProServiceUrl = config.emarketerProServiceUrl;
        const alertsServiceUrl = config.alertsServiceUrl;
        const profileUrl = emarketerProServiceUrl + '/MyAccount/EditProfile.aspx';
        const referAColleagueUrl = `${config.emarketerProServiceUrl}/Extranet/Registration/ReferralForm.aspx?ReturnUrl=${currentUrl}&RegistrationSource=branding`;
        const emUrl = config.emarketerBaseUrl;
        const logOutUrl = `${config.subscriptionsServiceUrl}/Logout`
            + (!enablePodRedirection ? '' : `?returnUrl=${encodeURIComponent(window.location.href)}`);

        const isMenuActive = this.props.active ? ' -active' : '';

        return (
            <div className={`user-menu${isMenuActive}`}>
                <img className="user-menu__arrow" alt={'arrow'}src={require('./images/menu-arrow.png')} width="23" height="11"/>
                <div className="user-menu__modal">
                    <div className="user-menu__account-info">
                    {
                        !isIpUser ?
                        <div className="name" data-analytics={`Branding-UserMenu-Email`}>{userEmail}</div> : null
                    }
                    </div>
                    {
                        !isIpUser ?
                        <MenuRow
                            title={'Profile & Newsletters'}
                            url={profileUrl}
                            icon={'icon-newspaper-o'} /> : null
                    }
                    {
                        !isIpUser ?
                        <MenuRow
                            title={'Alerts'}
                            url={alertsServiceUrl}
                            icon={'icon-bell-o'} /> : null
                    }
                    {
                        !isIpUser && canReferAColleague ?
                        <MenuRow
                            title={'Refer a Colleague'}
                            url={referAColleagueUrl}
                            icon={'icon-handshake-o'} /> : null
                    }
                    <MenuRow title={'Contact'}
                            url={`${emUrl}/contact`}
                            icon={'icon-paper-plane-o'}/>
                    <MenuRow title={'Logout'}
                            url={logOutUrl}
                            icon={'icon-sign-out'}/>
                </div>
            </div>
        );
    }
}
