import React, { Component } from 'react';
import FormatText from '../../../../helpers/FormatText';
import './Section.scss';

export default class MenuSection extends Component<{
    // props
    title?: string;
    url?: string;
    subTitle?: string;
    subUrl?: string;
    target?: string;
}, {
    // state
}> {

    public render() {

        const { title, url, subTitle, subUrl, target } = this.props;
        return (
            <div className="mobile-page-section">
                {
                    title ?
                    <a
                        className="mobile-page-section__title"
                        href={url}
                        target={target ? target : undefined}
                        data-analytics={`Branding-Mobile-Navigation-${FormatText(title)}`}
                    >
                        {title}
                    </a> : null
                }
                {
                    subTitle && subUrl ?
                        <a
                            className="mobile-page-section__subtitle"
                            href={subUrl}
                            target={target ? target : undefined}
                            data-analytics={`Branding-Mobile-Navigation-${FormatText(title)}-${FormatText(subTitle)}`}
                        >
                            {subTitle}
                        </a>
                        : null
                    }
                {this.props.children}
            </div>
        );
    }
}
