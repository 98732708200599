import React, { Component } from 'react';
import Bookmark from '../../theme/svgs/bookmark';
import '../../theme/fontAwesome.scss';
import './UserFavorites.scss';
import config from 'config';

interface IUserFavoritesProps {
    authenticated: boolean;
    isTransparent: boolean;
    scrolled?: boolean;
}

export default class UserFavorites extends Component<IUserFavoritesProps, {}> {

    public render() {

        const { isTransparent, scrolled } = this.props;

        const buttonStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-favorites__button${isT}${isS}`;
        };

        const iconStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-favorites__icon${isT}${isS}`;
        };
        const brandingUserFavoriteStyles = () => {
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-favorites${isS}`;
        };

        return (
            <div className={brandingUserFavoriteStyles()}>
                <div className={`branding-user-favorites__divider ${scrolled ? '-scrolled' : ''}`} />
                <a className={buttonStyles()}
                    href={config.favoritesServiceUrl}
                    data-analytics="Branding-Tools-Favorites">
                    <div className={`${iconStyles()} `}>
                        <Bookmark />
                    </div>
                </a>
            </div>
        );
    }
}
