import React, { Component } from 'react';
import './IEDeprecationBanner.scss';
import TriangleExclamation from '../../theme/svgs/triangle-exclamation';
import X from '../../theme/svgs/x';

export default class IEDeprecationBanner extends Component<{
    // props
}, {
    // state
    closed: boolean;
}> {
    constructor(props) {
        super(props);
        this.state = {
            closed: false,
        };

        this.handleIEBannerClose = this.handleIEBannerClose.bind(this);
    }

    private handleIEBannerClose(e: any) {
        this.setState({
            closed: true,
        });
    }

    public render() {
        const message = 'For the best experience, please use a more modern browser and navigate to EMARKETER.';
        const classNames = () => {
            const isAccepted = this.state.closed ? ' -hidden' : '';
            return `em-ie-deprecation-banner${isAccepted}`;
        };
        return (
            <div className={classNames()}>
                <TriangleExclamation />
                <div className="wrapper-span">
                    <span>{message}</span>
                </div>
                <button type="button" onClick={this.handleIEBannerClose} data-analytics={`Branding-IEDeprecationBanner-Close`} className="em-ie-deprecation-banner__button">
                    {<X />}
                </button>
            </div>
        );
    }
}
