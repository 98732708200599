import React, { Component } from 'react';
import LoadRemoteComponent from '../../../components/LoadRemoteComponent/LoadRemoteComponent';
import Loading from '../../Loading/Loading';
import config from '../../../config';
import './Menu.scss';

export default class Menu extends Component<{
    // props
    active: boolean;
    title: string;
    enablePodRedirection: boolean;
    returnToCurrentServiceAfterRegistration: boolean;
    returnUrlQueryString: string;
    registrationSource: string;
}, {
    // state
    email: string;
}> {

    constructor(props) {
        super(props);
        this.emailChanged = this.emailChanged.bind(this);
        this.state = {email: null};
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    public handleFormSubmit(e)  {
        e.preventDefault();
        return false;
    }

    public emailChanged(email) {
        this.setState({email});
    }

    public render() {

        const doesMyCompanySubURL = config.doesMyCompanySubURL;
        const isMenuActive = this.props.active ? ' -active' : '';
        const loginProps = {
            disablePodRedirection: !this.props.enablePodRedirection,
            returnToCurrentServiceAfterRegistration: this.props.returnToCurrentServiceAfterRegistration,
            returnUrlQueryString: this.props.returnUrlQueryString,
            registrationSource: this.props.registrationSource,
            emailChanged: this.emailChanged,
        };

        return (
            <div className={`login-menu${isMenuActive}`}>
                <img className="login-menu__arrow" alt={'arrow'} src={require('./images/menu-arrow.png')} width="23" height="11"/>
                <div className="login-menu__modal">
                    <div className="login-menu__headline">Get started with EMARKETER!</div>
                    <div className="login-menu__subheadline">Log in to your EMARKETER account</div>
                    <form className="login-menu__form" onSubmit={this.handleFormSubmit}>
                        <LoadRemoteComponent
                            url={config.subscriptionsSimpleLoginPluginUrl}
                            name="loginorsignupcomponent-topnavbare"
                            props={loginProps}>
                                <Loading />
                        </LoadRemoteComponent>
                    </form>
                    <a href={config.iForgotService + (this.state.email ? `?email=${this.state.email}` : '')}
                       data-analytics="Branding-Tools-Login-ForgotPassword"
                       className="login-menu__link">Forgot password?</a>
                    <a href={doesMyCompanySubURL}
                       data-analytics="Branding-Tools-Login-DoesCompanySubscribe"
                       className="login-menu__link">Does my company subscribe?</a>
                    <a href={`${config.emarketerBaseUrl}/about/`}
                       data-analytics="Branding-Tools-Login-AboutII"
                       className="login-menu__link">Learn more about EMARKETER</a>
                </div>
            </div>
        );
    }
}
