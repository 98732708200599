import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import config from '../../../../config';

// NEW TAXONOMY STRUCTURE OCT 2020 - INCLUDES SPECIAL BRANDING CASES
export const advertisingMarketing = {
    name: 'Advertising & Marketing',
    slug: 'advertising-marketing',
    children: [
        {
            name: 'Ad Spending',
            slug: 'ad-spending',
            children: [
                {
                    name: 'Ad Spending by Media',
                    slug: 'ad-spending-by-media',
                    children: [
                        { name: 'Cinema Ad Spending', slug: 'cinema-ad-spending' },
                        { name: 'Classified Ad Spending', slug: 'classified-ad-spending' },
                        { name: 'Digital Ad Spending', slug: 'digital-ad-spending' },
                        { name: 'Digital Display Ad Spending', slug: 'digital-display-ad-spending' },
                        { name: 'Digital Video Ad Spending', slug: 'digital-video-ad-spending' },
                        { name: 'Directory Ad Spending', slug: 'directory-ad-spending' },
                        { name: 'Email Ad Spending', slug: 'email-ad-spending' },
                        { name: 'Local Ad Spending', slug: 'local-ad-spending' },
                        { name: 'Magazine Ad Spending', slug: 'magazine-ad-spending' },
                        { name: 'Mobile Ad Spending', slug: 'mobile-ad-spending' },
                        { name: 'Newspaper Ad Spending', slug: 'newspaper-ad-spending' },
                        { name: 'Out-of-Home Ad Spending', slug: 'out-of-home-ad-spending' },
                        { name: 'Programmatic Ad Spending', slug: 'programmatic-ad-spending' },
                        { name: 'Radio Ad Spending', slug: 'radio-ad-spending' },
                        { name: 'Search Ad Spending', slug: 'search-ad-spending' },
                        { name: 'Social Media Ad Spending', slug: 'social-media-ad-spending' },
                        { name: 'TV Ad Spending', slug: 'tv-ad-spending' },
                    ],
                },
                { name: 'Total Media Ad Spending', slug: 'total-media-ad-spending' },
            ],
        },
        {
            name: 'Advertising',
            slug: 'advertising',
            children: [
                { name: 'Advertising Agencies', slug: 'advertising-agencies' },
                {
                    name: 'Advertising Performance Metrics',
                    slug: 'advertising-performance-metrics',
                    children: [
                        { name: 'Clickthrough Rate (CTR)', slug: 'clickthrough-rate-ctr' },
                        { name: 'Cost per Click (CPC)', slug: 'cost-per-click-cpc' },
                        { name: 'Cost per Thousand (CPM)', slug: 'cost-per-thousand-cpm' },
                        { name: 'Impressions', slug: 'impressions' },
                        { name: 'Viewability', slug: 'viewability' },
                    ],
                },
                { name: 'Creative Advertising', slug: 'creative-advertising' },
                {
                    name: 'Digital Advertising',
                    slug: 'digital-advertising',
                    children: [
                        { name: 'Display Advertising', slug: 'display-advertising' },
                        { name: 'Mobile Advertising', slug: 'mobile-advertising' },
                        { name: 'Programmatic Advertising', slug: 'programmatic-advertising' },
                        { name: 'Video Advertising', slug: 'video-advertising' },
                    ],
                },
                { name: 'Media Buying', slug: 'media-buying' },
                { name: 'TV Advertising', slug: 'tv-advertising' },
            ],
        },
        { name: 'AI in Marketing', slug: 'AI%20in%20Marketing' },
        {
            name: 'Marketing',
            slug: 'marketing',
            children: [
                { name: 'Brand Marketing', slug: 'brand-marketing' },
                {
                    name: 'Business Strategies & Trends',
                    slug: 'business-strategies-trends',
                    children: [
                        { name: 'Digital Transformation', slug: 'digital-transformation' },
                        { name: 'Senior & C-Level Executives', slug: 'senior-c-level-executives' },
                    ],
                 },
                { name: 'Consumer Attitudes on Marketing', slug: 'consumer-attitudes-on-marketing' },
                { name: 'Consumer Privacy & Security', slug: 'consumer-privacy-security' },
                { name: 'Content Marketing', slug: 'content-marketing' },
                { name: 'Customer Experience', slug: 'customer-experience' },
                { name: 'Data', slug: 'data' },
                { name: 'Direct Marketing', slug: 'direct-marketing' },
                { name: 'Email Marketing', slug: 'email-marketing' },
                { name: 'Marketing Measurement Surveys', slug: 'marketing-measurement-surveys' },
                { name: 'Marketing Spending', slug: 'marketing-spending' },
                { name: 'Marketing Tactics', slug: 'marketing-tactics' },
                { name: 'Marketing Technology', slug: 'marketing-technology' },
                { name: 'Omnichannel Marketing', slug: 'omnichannel-marketing' },
                { name: 'Personalization', slug: 'personalization' },
                { name: 'Retail Marketing', slug: 'retail-marketing' },
                {
                    name: 'Search Marketing',
                    slug: 'search-marketing',
                    children: [
                        { name: 'Paid Search', slug: 'paid-search' },
                        { name: 'Search Engine Optimization (SEO)', slug: 'search-engine-optimization-seo' },
                    ],
                },
                {
                    name: 'Social Media Marketing',
                    slug: 'social-media-marketing',
                    children: [{ name: 'Influencer Marketing', slug: 'influencer-marketing' }],
                },
            ],
        },
        {
            name: 'Media',
            slug: 'media',
            children: [
                {
                    name: 'Books',
                    slug: 'books',
                    children: [{ name: 'Ebooks', slug: 'ebooks' }],
                },
                {
                    name: 'Internet',
                    slug: 'internet',
                    children: [
                        { name: 'Browsers', slug: 'browsers' },
                        { name: 'Computers', slug: 'computers' },
                        {
                            name: 'Digital Content',
                            slug: 'digital-content',
                            children: [{ name: 'Paid Content', slug: 'paid-content' }],
                        },
                        { name: 'Email', slug: 'email' },
                        { name: 'Internet Access Devices', slug: 'internet-access-devices' },
                        { name: 'Internet Access Locations', slug: 'internet-access-locations' },
                        { name: 'Internet Activities', slug: 'internet-activities' },
                        { name: 'Internet Users', slug: 'internet-users' },
                        { name: 'Search', slug: 'search' },
                        { name: 'Top Sites', slug: 'top-sites' },
                    ],
                },
                { name: 'Magazines', slug: 'magazines' },
                {
                    name: 'Media Consumption',
                    slug: 'media-consumption',
                    children: [
                        { name: 'Simultaneous Media Usage', slug: 'simultaneous-media-usage' },
                        { name: 'Time Spent with Media', slug: 'time-spent-with-media' },
                    ],
                },
                { name: 'Movies', slug: 'movies' },
                {
                    name: 'Music',
                    slug: 'music',
                    children: [
                        { name: 'Digital Music', slug: 'digital-music' },
                        { name: 'Mobile Music', slug: 'mobile-music' },
                    ],
                },
                { name: 'News', slug: 'news' },
                { name: 'Podcasts', slug: 'podcasts' },
                { name: 'Politics', slug: 'politics' },
                {
                    name: 'Radio',
                    slug: 'radio',
                    children: [{ name: 'Digital Radio', slug: 'digital-radio' }],
                },
                {
                    name: 'TV',
                    slug: 'tv',
                    children: [
                        { name: 'Connected TVs', slug: 'connected-tvs' },
                        { name: 'Pay TV', slug: 'pay-tv' },
                    ],
                },
                { name: 'Video Games', slug: 'video-games' },
            ],
        },
        {
            name: 'Social Media',
            slug: 'social-media',
            children: [
                { name: 'Mobile Social Media', slug: 'mobile-social-media' },
                { name: 'Social Media Usage', slug: 'social-media-usage' },
                { name: 'Social Media Users', slug: 'social-media-users' },
            ],
        },
        {
            name: 'Video',
            slug: 'video',
            children: [
                { name: 'Digital Video', slug: 'digital-video' },
                { name: 'Mobile Video', slug: 'mobile-video' },
                { name: 'Video-on-Demand (VOD)', slug: 'video-on-demand-vod' },
            ],
        },
    ],
};

export const ecommerceRetail = {
    name: 'Ecommerce & Retail',
    slug: 'ecommerce-retail',
    children: [
        { name: 'AI in Commerce', slug: 'AI%20in%20Commerce' },
        {
            name: 'Consumer Behavior',
            slug: 'consumer-behavior',
            children: [
                { name: 'Digital Shoppers & Buyers', slug: 'digital-shoppers-buyers' },
                { name: 'In-Store Shoppers & Buyers', slug: 'in-store-shoppers-buyers' },
                { name: 'Mobile Shoppers & Buyers', slug: 'mobile-shoppers-buyers' },
            ],
        },
        { name: 'Cross-Border Ecommerce', slug: 'cross-border-ecommerce' },
        {
            name: 'Ecommerce Sales',
            slug: 'ecommerce-sales',
            children: [
                {
                    name: 'B2C Ecommerce Sales',
                    slug: 'b2c-ecommerce-sales',
                    children: [
                        { name: 'Digital Travel Sales', slug: 'digital-travel-sales' },
                        { name: 'Retail Ecommerce Sales', slug: 'retail-ecommerce-sales' },
                    ],
                },
            ],
        },
        { name: 'Ecommerce Tactics', slug: 'ecommerce-tactics' },
        { name: 'Holiday & Seasonal Shopping', slug: 'holiday-seasonal-shopping' },
        { name: 'Loyalty', slug: 'loyalty' },
        {
            name: 'Mcommerce',
            slug: 'mcommerce',
            children: [{ name: 'Retail Mcommerce Sales', slug: 'retail-mcommerce-sales' }],
        },
        {
            name: 'Payments',
            slug: 'payments',
            children: [
                { name: 'Checkout', slug: 'checkout' },
                { name: 'Credit & Debit', slug: 'credit-debit' },
                { name: 'Digital Payments', slug: 'digital-payments' },
                { name: 'Mobile Payments', slug: 'mobile-payments' },
                { name: 'Transfers', slug: 'transfers' },
            ],
        },
        {
            name: 'Promotions',
            slug: 'promotions',
            children: [
                { name: 'Coupons', slug: 'coupons' },
            ],
         },
        {
            name: 'Retail Categories',
            slug: 'retail-categories',
            children: [
                { name: 'Apparel & Accessories', slug: 'apparel-accessories' },
                { name: 'Consumer Electronics', slug: 'consumer-electronics' },
                {
                    name: 'Groceries & Consumer Products (CPG)',
                    slug: 'groceries-consumer-products-cpg',
                    children: [
                        { name: 'Food & Beverage', slug: 'food-beverage' },
                        { name: 'Household Products', slug: 'household-products' },
                        { name: 'Personal Care & Beauty', slug: 'personal-care-beauty' },
                    ],
                },
                { name: 'Luxury Goods', slug: 'luxury-goods' },
                { name: 'Toys & Games', slug: 'toys-games' },
            ],
        },
        { name: 'Retail Media', slug: 'retail-media' },
        { name: 'Retail Performance Metrics', slug: 'retail-performance-metrics' },
        { name: 'Retail Sales', slug: 'retail-sales' },
        { name: 'Sharing Economy', slug: 'sharing-economy' },
        { name: 'Shipping & Delivery', slug: 'shipping-delivery' },
        { name: 'Social Commerce', slug: 'social-commerce' },
    ],
};

export const financialServices = {
    name: 'Financial Services',
    slug: 'financial-services',
    children: [
        {
            name: 'Banking',
            slug: 'banking',
            children: [
                { name: 'Branches', slug: 'branches' },
                {
                    name: 'Digital Banking',
                    slug: 'digital-banking',
                    children: [
                        { name: 'Neobanks', slug: 'neobanks' },
                        { name: 'Open Banking', slug: 'open-banking' },
                    ],
                },
                { name: 'Mobile Banking', slug: 'mobile-banking' },
                { name: 'Small Business (SMB) Banking', slug: 'small-business-smb-banking' },
            ],
        },
        { name: 'Insurance', slug: 'insurance' },
        {
            name: 'Payments',
            slug: 'payments',
            children: [
                { name: 'Checkout', slug: 'checkout' },
                { name: 'Credit & Debit', slug: 'credit-debit' },
                { name: 'Digital Payments', slug: 'digital-payments' },
                { name: 'Ecommerce', slug: 'ecommerce' },
                { name: 'Mobile Payments', slug: 'mobile-payments' },
                { name: 'Transfers', slug: 'transfers' },
            ],
        },
        {
            name: 'Wealth & Asset Management',
            slug: 'wealth-asset-management',
            children: [
                { name: 'Asset Management', slug: 'asset-management' },
                { name: 'Digital Assets', slug: 'digital-assets' },
                { name: 'Personal Finance', slug: 'personal-finance' },
                { name: 'Robo-advisors', slug: 'robo-advisors' },
                { name: 'Wealth Management', slug: 'wealth-management' },
            ],
        },
    ],
};

export const health = {
    name: 'Health',
    slug: 'health',
    children: [
        {
            name: 'Digital Health',
            slug: 'digital-health',
            children: [
                { name: 'Mobile Health', slug: 'mobile-health' },
                { name: 'Telehealth', slug: 'telehealth' },
            ],
        },
        { name: 'Healthcare Providers', slug: 'healthcare-providers' },
        { name: 'Prescription & OTC Drugs', slug: 'prescription-otc-drugs' },
    ],
};

export const technology = {
    name: 'Technology',
    slug: 'technology',
    children: [
        { name: 'Artificial Intelligence (AI)', slug: 'artificial-intelligence-ai' },
        {
            name: 'Augmented & Virtual Reality',
            slug: 'augmented-virtual-reality',
            children: [{ name: 'Metaverse', slug: 'metaverse' }],
        },
        { name: 'Cybersecurity', slug: 'cybersecurity' },
        { name: 'Internet of Things (IoT)', slug: 'internet-of-things-iot' },
        {
            name: 'Mobile',
            slug: 'mobile',
            children: [
                { name: '5G', slug: '5g' },
                { name: 'Location Data', slug: 'location-data' },
                {
                    name: 'Mobile Activities',
                    slug: 'mobile-activities',
                    children: [
                        { name: 'Mobile Apps', slug: 'mobile-apps' },
                        { name: 'Mobile Games', slug: 'mobile-games' },
                        { name: 'Mobile Internet', slug: 'mobile-internet' },
                        { name: 'Mobile Messaging', slug: 'mobile-messaging' },
                        { name: 'Mobile Search', slug: 'mobile-search' },
                    ],
                },
                { name: 'Mobile Carriers', slug: 'mobile-carriers' },
                {
                    name: 'Mobile Devices',
                    slug: 'mobile-devices',
                    children: [
                        { name: 'Smartphones', slug: 'smartphones' },
                        { name: 'Tablets', slug: 'tablets' },
                        { name: 'Wearables', slug: 'wearables' },
                    ],
                },
                {
                    name: 'Mobile Users',
                    slug: 'mobile-users',
                    children: [
                        { name: 'Smartphone Users', slug: 'smartphone-users' },
                        { name: 'Tablet Users', slug: 'tablet-users' },
                    ],
                },
            ],
        },
        { name: 'Smart Speakers & Voice Assistants', slug: 'smart-speakers-voice-assistants' },
    ],
};

export const moreIndustries = {
    name: 'More Industries',
    slug: 'more-industries',
    children: [
        { name: 'Automotive', slug: 'automotive' },
        {
            name: 'B2B',
            slug: 'b2b',
            children: [
                {
                    name: 'B2B Marketing',
                    slug: 'b2b-marketing',
                    children: [
                        { name: 'Lead Management', slug: 'lead-management' },
                    ],
                 },
                { name: 'B2B Sales', slug: 'b2b-sales' },
                { name: 'B2B Social Media', slug: 'b2b-social-media' },
            ],
        },
        {
            name: 'Financial Services',
            slug: 'financial-services',
            children: [
                {
                    name: 'Banking',
                    slug: 'banking',
                    children: [
                        { name: 'Branches', slug: 'branches' },
                        {
                            name: 'Digital Banking',
                            slug: 'digital-banking',
                            children: [
                                { name: 'Neobanks', slug: 'neobanks' },
                                { name: 'Open Banking', slug: 'open-banking' },
                            ],
                        },
                        { name: 'Mobile Banking', slug: 'mobile-banking' },
                        { name: 'Small Business (SMB) Banking', slug: 'small-business-smb-banking' },
                    ],
                },
                { name: 'Insurance', slug: 'insurance' },
                {
                    name: 'Payments',
                    slug: 'payments',
                    children: [
                        { name: 'Checkout', slug: 'checkout' },
                        { name: 'Credit & Debit', slug: 'credit-debit' },
                        { name: 'Digital Payments', slug: 'digital-payments' },
                        { name: 'Ecommerce', slug: 'ecommerce' },
                        { name: 'Mobile Payments', slug: 'mobile-payments' },
                        { name: 'Transfers', slug: 'transfers' },
                    ],
                },
                {
                    name: 'Wealth & Asset Management',
                    slug: 'wealth-asset-management',
                    children: [
                        { name: 'Asset Management', slug: 'asset-management' },
                        { name: 'Digital Assets', slug: 'digital-assets' },
                        { name: 'Personal Finance', slug: 'personal-finance' },
                        { name: 'Robo-advisors', slug: 'robo-advisors' },
                        { name: 'Wealth Management', slug: 'wealth-management' },
                    ],
                },
            ],
        },
        {
            name: 'Health',
            slug: 'health',
            children: [
                {
                    name: 'Digital Health',
                    slug: 'digital-health',
                    children: [
                        { name: 'Mobile Health', slug: 'mobile-health' },
                        { name: 'Telehealth', slug: 'telehealth' },
                    ],
                },
                { name: 'Healthcare Providers', slug: 'healthcare-providers' },
                { name: 'Prescription & OTC Drugs', slug: 'prescription-otc-drugs' },
            ],
        },
        { name: 'Media & Entertainment', slug: 'media-entertainment' },
        { name: 'Real Estate', slug: 'real-estate' },
        { name: 'Restaurants & Dining', slug: 'restaurants-dining' },
        { name: 'Small Business (SMB)', slug: 'small-business-smb' },
        { name: 'Sports', slug: 'sports' },
        {
            name: 'Technology',
            slug: 'technology',
            children: [
                { name: 'Artificial Intelligence (AI)', slug: 'artificial-intelligence-ai' },
                {
                    name: 'Augmented & Virtual Reality',
                    slug: 'augmented-virtual-reality',
                    children: [{ name: 'Metaverse', slug: 'metaverse' }],
                },
                { name: 'Cybersecurity', slug: 'cybersecurity' },
                { name: 'Internet of Things (IoT)', slug: 'internet-of-things-iot' },
                {
                    name: 'Mobile',
                    slug: 'mobile',
                    children: [
                        { name: '5G', slug: '5g' },
                        { name: 'Location Data', slug: 'location-data' },
                        {
                            name: 'Mobile Activities',
                            slug: 'mobile-activities',
                            children: [
                                { name: 'Mobile Apps', slug: 'mobile-apps' },
                                { name: 'Mobile Games', slug: 'mobile-games' },
                                { name: 'Mobile Internet', slug: 'mobile-internet' },
                                { name: 'Mobile Messaging', slug: 'mobile-messaging' },
                                { name: 'Mobile Search', slug: 'mobile-search' },
                            ],
                        },
                        { name: 'Mobile Carriers', slug: 'mobile-carriers' },
                        {
                            name: 'Mobile Devices',
                            slug: 'mobile-devices',
                            children: [
                                { name: 'Smartphones', slug: 'smartphones' },
                                { name: 'Tablets', slug: 'tablets' },
                                { name: 'Wearables', slug: 'wearables' },
                            ],
                        },
                        {
                            name: 'Mobile Users',
                            slug: 'mobile-users',
                            children: [
                                { name: 'Smartphone Users', slug: 'smartphone-users' },
                                { name: 'Tablet Users', slug: 'tablet-users' },
                            ],
                        },
                    ],
                },
                { name: 'Smart Speakers & Voice Assistants', slug: 'smart-speakers-voice-assistants' },
            ],
        },
        {
            name: 'Telecom',
            slug: 'telecom',
            children: [
                { name: 'Broadband', slug: 'broadband' },
            ],
        },
        {
            name: 'Travel',
            slug: 'travel',
            children: [
                { name: 'Airlines', slug: 'airlines' },
                { name: 'Digital Travel', slug: 'digital-travel' },
                { name: 'Hotels & Lodging', slug: 'hotels-lodging' },
            ],
        },
    ],
};

const pod = 'na1';
const enableIndustryKPIs = config.enableIndustryKPIs === 'true';

export const proPlus = {
    name: 'PRO+',
    children: [
        { name: 'Forecasts', url: BuildServiceUrl(pod, 'forecasts') },
        ...enableIndustryKPIs ? [{ name: 'Industry KPIs', url: BuildServiceUrl(pod, 'industry-kpis') }] : [],
        { name: 'Compare Forecasts', url: `${BuildServiceUrl(pod, 'premium-forecasts')}/metric/5,66,355,402,2625,2631,8700` },
        { name: 'Build a Forecast Comparison', url: BuildServiceUrl(pod, 'premium-forecasts') },
        { name: 'Historical Forecasts', url: `${config.emarketerBaseUrl}/products/pro-plus/#historical-forecasts`, target: '_blank' },
    ],
};
