import React, { Component } from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import NavigationLink from './NavigationLink/NavigationLink';
import Free from './NavigationMenu/MenuTemplates/Free';
import PROPlus from './NavigationMenu/MenuTemplates/PROPlus';
import Subscribe from './NavigationMenu/MenuTemplates/Subscribe';
import DeepCascadeMenu from './NavigationMenu/MenuTemplates/DeepCascadeMenu';
import { advertisingMarketing, ecommerceRetail, moreIndustries } from './NavigationMenu/MenuTemplates/Data';
import './Navigation.scss';
import BuildServiceUrl from '../../helpers/ServiceUrlBuilder';
import config from '../../config';

export default class Navigation extends Component<
    {
        // props
        authenticated: boolean;
        isTransparent: boolean;
        updateMenuActive: any;
        pod: string;
        updateFixedSearchBarDisplay: any;
        scrolled?: boolean;
        stickySearchBarDisplay: boolean;
        isCorporate: boolean;
        isMobile: boolean;
        isSmallDesktop: boolean;
        enableIndustryKPIs?: boolean;
        hasFreemiumRole: boolean;
    },
    {
        // state
        selectedMenuItem: any;
        ref: any;
        scrollY: number;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            ref: '',
            selectedMenuItem: null,
            scrollY: 0,
        };
        this.closeMenu = this.closeMenu.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateSelected = this.updateSelected.bind(this);
        this.updateOnClick = this.updateOnClick.bind(this);
        this.watchScroll = this.watchScroll.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('scroll', this.watchScroll);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setWrapperRef(node) {
        this.setState({
            ref: node,
        });
    }

    private closeMenu() {
        this.setState({
            selectedMenuItem: null,
        });
        this.props.updateMenuActive(false);
    }

    private watchScroll() {
        if (
            this.state.scrollY === 0 &&
            this.state.selectedMenuItem !== null &&
            (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0 || window.pageYOffset > 0)
        ) {
            this.closeMenu();
        }
        this.setState({ scrollY: document.body.scrollTop });
    }

    private updateOnClick() {
        this.props.updateFixedSearchBarDisplay(true);
    }

    private handleClickOutside(event) {
        if (this.state.selectedMenuItem) {
            if (this.state.ref && !this.state.ref.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
        // this.props.updateMenuActive(false); this seems to be not needed as closeMenu is already taking care of it

    }

    public updateSelected(s) {
        this.setState({
            selectedMenuItem: s === this.state.selectedMenuItem ? null : s,
        });
        this.props.updateMenuActive(s === this.state.selectedMenuItem ? false : true);
    }

    public render() {
        const { isTransparent, authenticated, pod, scrolled, isSmallDesktop, stickySearchBarDisplay, enableIndustryKPIs, hasFreemiumRole } = this.props;

        // Title fixing is due to limited navigation real estate on public version of navigation
        const isSubscriber = (pod.toLowerCase() === 'na1');
        const advertisingMarketingName = 'Advertising & Marketing';
        const ecommerceName = 'Ecommerce';
        const proPlusName = 'PRO+';
        const moreIndustriesName = isSubscriber ? 'More Industries' : isSmallDesktop ? 'More' : 'More Industries';
        const plansPricingName = isSubscriber ? 'Plans & Pricing' : isSmallDesktop ? 'Pricing' : 'Plans & Pricing';
        const dailyNewsName = isSubscriber ? 'Daily News' : isSmallDesktop ? 'News' : 'Daily News';

        const getSelectedMenuTemplate = (t: string) => {
            let template = null;
            switch (t) {
                case advertisingMarketingName:
                    template = <DeepCascadeMenu data={advertisingMarketing} pod={pod} type={'topic'} />;
                    break;
                case ecommerceName:
                    template = <DeepCascadeMenu data={ecommerceRetail} pod={pod} type={'industry'} />;
                    break;
                case moreIndustriesName:
                    template = <DeepCascadeMenu data={moreIndustries} pod={pod} type={'industry'} menuCloseFn={this.closeMenu} />;
                    break;
                case proPlusName:
                    template = <PROPlus pod={pod} enableIndustryKPIs={enableIndustryKPIs} />;
                    break;
                case plansPricingName:
                    template = <Subscribe />;
                    break;
                case dailyNewsName:
                    template = <Free pod={pod} />;
                    break;
            }
            return template;
        };

        const searchIconStyles = () => {
            const isS = scrolled ? ' -visible' : ` -hidden`;
            return `icon-search navigation__search${isS}`;
        };

        const navigationStyles = () => {
            const isH = stickySearchBarDisplay && scrolled ? ` -hidden` : '';
            const isS = scrolled ? ` -scrolled` : '';
            return `navigation${isH}${isS}`;
        };

        return (
            <div className={navigationStyles()} ref={this.setWrapperRef}>
                <i className={searchIconStyles()} onClick={this.updateOnClick} data-analytics="Branding-Navigation-Search-Toggle" />

                <div className="navigation__cascade">
                    <NavigationItem
                        text={advertisingMarketingName}
                        updateSelected={this.updateSelected}
                        isTransparent={isTransparent}
                        active={this.state.selectedMenuItem === advertisingMarketingName}
                        scrolled={this.props.scrolled}
                    />
                    {this.state.selectedMenuItem && this.state.selectedMenuItem === advertisingMarketingName ? (
                        <NavigationMenu active={true} menu={getSelectedMenuTemplate(this.state.selectedMenuItem)} />
                    ) : null}
                </div>

                <div className="navigation__cascade">
                    <NavigationItem
                        text={ecommerceName}
                        updateSelected={this.updateSelected}
                        isTransparent={isTransparent}
                        active={this.state.selectedMenuItem === ecommerceName}
                        scrolled={this.props.scrolled}
                    />
                    {this.state.selectedMenuItem && this.state.selectedMenuItem === ecommerceName ? (
                        <NavigationMenu active={true} menu={getSelectedMenuTemplate(this.state.selectedMenuItem)} />
                    ) : null}
                </div>

                <div className="navigation__cascade">
                    <NavigationItem
                        text={moreIndustriesName}
                        updateSelected={this.updateSelected}
                        isTransparent={isTransparent}
                        active={this.state.selectedMenuItem === moreIndustriesName}
                        scrolled={this.props.scrolled}
                    />
                    {this.state.selectedMenuItem && this.state.selectedMenuItem === moreIndustriesName ? (
                        <NavigationMenu active={true} menu={getSelectedMenuTemplate(this.state.selectedMenuItem)} />
                    ) : null}
                </div>

                <NavigationLink
                    text="Forecasts"
                    url={BuildServiceUrl(pod, 'forecasts')}
                    isTransparent={isTransparent}
                    scrolled={this.props.scrolled}
                    hideNarrow={true}
                />

                {enableIndustryKPIs ? (
                <NavigationLink
                    text="Industry KPIs"
                    url={BuildServiceUrl(pod, 'industry-kpis')}
                    isTransparent={isTransparent}
                    scrolled={this.props.scrolled}
                    hideNarrow={true}
                />
                ) : null}

                <div className="navigation__cascade">
                    <NavigationItem
                        text={proPlusName}
                        updateSelected={this.updateSelected}
                        isTransparent={isTransparent}
                        active={this.state.selectedMenuItem === proPlusName}
                        scrolled={this.props.scrolled}
                    />
                    {this.state.selectedMenuItem && this.state.selectedMenuItem === proPlusName ? (
                        <NavigationMenu active={true} menu={getSelectedMenuTemplate(this.state.selectedMenuItem)} />
                    ) : null}
                </div>

                {hasFreemiumRole ? (
                <NavigationLink
                    text="Pricing"
                    url={`${config.publicUrl}pricing`}
                    target="_blank"
                    isTransparent={isTransparent}
                    scrolled={this.props.scrolled}
                    hideNarrow={true}
                />
                ) : null}

                <NavigationLink
                    text="Events"
                    url={`${config.publicUrl}events`}
                    target="_blank"
                    isTransparent={isTransparent}
                    scrolled={this.props.scrolled}
                    hideNarrow={true}
                />

                <NavigationLink
                    text="Podcasts"
                    url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-podcast`}
                    isTransparent={isTransparent}
                    scrolled={this.props.scrolled}
                    hideNarrow={true}
                />

                {!authenticated ? (
                    <NavigationItem
                        text={plansPricingName}
                        isTransparent={isTransparent}
                        isDarkMode={true}
                        divider={'left'}
                        updateSelected={this.updateSelected}
                        active={this.state.selectedMenuItem === plansPricingName}
                        scrolled={this.props.scrolled}
                    />
                ) : null}

                {!authenticated ? (
                    <NavigationItem
                        text={dailyNewsName}
                        isTransparent={isTransparent}
                        updateSelected={this.updateSelected}
                        active={this.state.selectedMenuItem === dailyNewsName}
                        scrolled={this.props.scrolled}
                    />
                ) : null}

                {this.state.selectedMenuItem && (this.state.selectedMenuItem === plansPricingName || this.state.selectedMenuItem === dailyNewsName) ? (
                    <NavigationMenu
                        active={
                            this.state.selectedMenuItem && (this.state.selectedMenuItem === plansPricingName || this.state.selectedMenuItem === dailyNewsName)
                        }
                        menu={getSelectedMenuTemplate(this.state.selectedMenuItem)}
                        wide={true}
                    />
                ) : null}
            </div>
        );
    }
}
