import React, { Component } from 'react';
import LoadRemoteComponent from '../../../../components/LoadRemoteComponent/LoadRemoteComponent';
import Loading from '../../../Loading/Loading';
import MenuRow from '../../../../components/UserMenu/Menu/MenuRow/MenuRow';
import NavigationMenuLink from '../../../../components/Navigation/NavigationMenu/NavigationMenuLink';
import config from '../../../../config';
import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import CalendarIcon from '../../../../theme/svgs/calendar';

export default class UserMenuPage extends Component<
    {
        // props
        authenticated: boolean;
        isIpUser: boolean;
        pod: string;
        enablePodRedirection: boolean;
        returnToCurrentServiceAfterRegistration: boolean;
        returnUrlQueryString: string;
        registrationSource: string;
        userEmail: string;
        menuOpen: boolean;
        openCalMenuFn: any;
    },
    {
        // state
        email: string;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
        };
        this.emailChanged = this.emailChanged.bind(this);
    }

    public emailChanged(email) {
        this.setState({ email });
    }

    public render() {
        const {
            authenticated,
            isIpUser,
            enablePodRedirection,
            userEmail,
            menuOpen,
            pod,
            returnToCurrentServiceAfterRegistration,
            returnUrlQueryString,
            registrationSource,
            openCalMenuFn,
        } = this.props;

        const emarketerProServiceUrl = config.emarketerProServiceUrl;
        // const searchHostUrl = BuildServiceUrl(pod, 'search1');
        const alertsServiceUrl = config.alertsServiceUrl;
        const profileUrl = emarketerProServiceUrl + '/MyAccount/EditProfile.aspx';
        const contactUrl = config.emarketerBaseUrl + '/contact';
        const logOutUrl = `${config.subscriptionsServiceUrl}/Logout` + (!enablePodRedirection ? '' : `?returnUrl=${encodeURIComponent(window.location.href)}`);
        const currentUrl = window.location.href;
        const referAColleagueUrl = `${config.emarketerProServiceUrl}/Extranet/Registration/ReferralForm.aspx?ReturnUrl=${currentUrl}&RegistrationSource=branding`;
        const emarketerUrl = config.emarketerServiceUrl;
        const doesMyCompanyUrl = `${emarketerUrl}corporate/doescompany`;
        const plansPricingUrl = `${emarketerUrl}corporate/products-plans`;

        const loginProps = {
            disablePodRedirection: !enablePodRedirection,
            returnToCurrentServiceAfterRegistration,
            returnUrlQueryString,
            registrationSource,
            emailChanged: this.emailChanged,
        };

        return (
            <div className="mobile-page__user-menu">
                {!authenticated && menuOpen ? (
                    <div className="mobile-page__login">
                        <React.Suspense fallback={<Loading />}>
                            <div className="headline">
                                Already a client?
                                <br />
                                Log in to your EMARKETER account.
                            </div>
                            <LoadRemoteComponent url={config.subscriptionsSimpleLoginPluginUrl} name="loginorsignupcomponent-topnavbare" props={loginProps}>
                                <Loading />
                            </LoadRemoteComponent>
                            <NavigationMenuLink
                                menuName={'Mobile'}
                                text={'Forgot Password?'}
                                url={`${config.iForgotService}${this.state.email ? `?email=${this.state.email}` : ''}`}
                            />
                        </React.Suspense>
                    </div>
                ) : null}
                {/* LOGGED IN */}
                {authenticated ? (
                    <div className="mobile-page__account-info">
                        <div className="name" data-analytics={`Branding-UserMenu-Mobile-Email`}>
                            {userEmail}
                        </div>
                    </div>
                ) : null}
                {/* LOGGED IN - SPECIAL CASE - ONLY SHOW TO NON IPAUTH */}
                {!isIpUser && authenticated ? (
                    <div className="mobile-page__row">
                        <MenuRow title={'Profile & Newsletters'} url={profileUrl} icon={'icon-newspaper-o'} />
                        {config.enableCalendarWidget ? (
                            <MenuRow title={'Upcoming & Noteworthy Content'} func={openCalMenuFn} svgIcon={<CalendarIcon />} />
                        ) : null}
                        <MenuRow title={'Alerts'} url={alertsServiceUrl} icon={'icon-bell-o'} />
                    </div>
                ) : null}
                {/* LOGGED IN */}
                {authenticated ? (
                    <div className="mobile-page__row">
                        <MenuRow title={'Refer a Colleague'} url={referAColleagueUrl} icon={'icon-handshake-o'} />
                        <MenuRow title={'Contact'} url={contactUrl} icon={'icon-paper-plane-o'} />
                        <MenuRow title={'Logout'} url={logOutUrl} icon={'icon-sign-out'} />
                    </div>
                ) : null}
                {/* LOGGED OUT */}
                {!authenticated ? (
                    <div className="mobile-page__row">
                        {config.enableCalendarWidget ? (
                            <MenuRow title={'Upcoming & Noteworthy Content'} func={openCalMenuFn} svgIcon={<CalendarIcon />} />
                        ) : null}
                        <MenuRow title={'Plans & Pricing'} url={plansPricingUrl} icon={'icon-info-circle'} />
                        <MenuRow title={'Do I already have access?'} url={doesMyCompanyUrl} icon={'icon-question-circle-o'} />
                    </div>
                ) : null}
            </div>
        );
    }
}
