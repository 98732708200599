import React, { Component } from 'react';
import FormatText from '../../../../../helpers/FormatText';
import Loading from '../../../../Loading/Loading';
import BuildServiceUrl from '../../../../../helpers/ServiceUrlBuilder';

export default class List extends Component<
    {
        // props
        data: any;
        type: string;
        pod: string;
        renderContent: boolean;
    },
    {
        // state
    }
> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        const { data, type, pod } = this.props;

        const urlPath = (t) => {
            let path = '';
            switch (t) {
                case 'Topics':
                    path = '/topic/';
                    break;
                case 'Industries':
                    path = '/industry/';
                    break;
                case 'Categories':
                    path = '/category/';
                    break;
                default:
                    path = '/topic/';
                    break;
            }
            return path;
        };

        const typeSpecialCases = (slug, type) => {
            let finalType = '';
            switch (slug) {
                case 'payments':
                    finalType = 'Industries';
                    break;
                case 'AI%20in%20Marketing':
                    finalType = 'Categories';
                    break;
                case 'AI%20in%20Commerce':
                    finalType = 'Categories';
                    break;
                default:
                    finalType = type;
                    break;
            }
            return finalType;
        };

        const list = data.map((item, index) => {
            if (item.Name === 'See All Topics') {
                return null;
            }

            return (
                <li key={index}>
                    <a
                        data-analytics={`Branding-Mobile-Navigation-${FormatText(type)}-${FormatText(item.name)}`}
                        href={item.url ? item.url : `${BuildServiceUrl(pod, 'topics')}${urlPath(typeSpecialCases(item.slug, type))}${item.slug}`}
                        target={item.target ? item.target : undefined}
                    >
                        {item.name}
                    </a>
                </li>
            );
        });

        const listStyles = () => {
            const isLong = data.length >= 10 ? ' -long' : '';
            return `mobile-page-section__list${isLong}`;
        };

        return this.props.renderContent ? (
            <React.Suspense fallback={<Loading />}>
                <ul className={listStyles()}>{list}</ul>
            </React.Suspense>
        ) : null;
    }
}
