import config, { cookieDomain } from '../config';
import { ANONYMOUS_POD } from '../consts/pods';
import Cookies from 'js-cookie';
import { resetCookie } from './CookieService';

export default class AuthService {

  public static Authenticate(accessTokenCookieExists: boolean, enablePodRedirection: boolean, currentPod: string) {
    const areInPoddedServicePod = (currentPod || '').toLowerCase() !== ANONYMOUS_POD.toLowerCase();
    const hasPR = enablePodRedirection ? `&pr=${enablePodRedirection}` : '';
    if (areInPoddedServicePod && !accessTokenCookieExists) {
      resetCookie('em_uid', cookieDomain);
      resetCookie('em_extId', cookieDomain);

      return `${config.subscriptionsServiceUrl}/Account/Login?returnUrl=${encodeURIComponent(window.location.href)}${hasPR}`;

    }
    return null;
  }
}
