import React, { Component } from 'react';
import Loading from '../../Loading/Loading';
import LoadRemoteComponent from '../../LoadRemoteComponent/LoadRemoteComponent';
import config from '../../../config';
import './Menu.scss';

export default class Menu extends Component<{
    // props
    active: boolean;
    title: string;
}, {
    // state
}> {

    constructor(props) {
        super(props);
    }

    public render() {

        const isMenuActive = this.props.active ? ' -active' : '';

        return (
            <div className={`cal-menu${isMenuActive}`}>
                <img className="cal-menu__arrow" alt={'arrow'} src={require('./images/menu-arrow.png')} width="23" height="11" />
                <div className="cal-menu__modal">
                    <LoadRemoteComponent
                        url={config.noteworthyContentService}
                        name="noteworthycontent"
                        props={null}>
                            <Loading />
                    </LoadRemoteComponent>
                </div>
            </div>
        );
    }
}
