import config from '../config';
import { ANONYMOUS_POD } from '../consts/pods';
export default class PodService {
    public static IsUrlInRightPod(url: string, suppliedPod: string) {
        if (url.indexOf('localhost') >= 0) {
            return true;
        }

        const pod = (suppliedPod || 'na1').toLowerCase();
        const urlWithoutProto = url.indexOf('http') === 0 ? url.replace('http://', '').replace('https://', '') : url;
        let subdomain = urlWithoutProto.split('.')[0];
        if (subdomain.indexOf('origin-') === 0) {
            subdomain = subdomain.substring('origin-'.length);
        }
        if (subdomain.indexOf('-') === -1) {
            if (subdomain === 'totalaccess') {
                return pod === 'na1';
            }
            return false;
        }

        const subdomainParts = subdomain.split('-');
        const urlPod = subdomainParts.length > 1 ? subdomainParts[subdomainParts.length - 1] : '';
        return urlPod.toLowerCase() === pod;
    }

    private static isURL(str) {
        const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        const url = new RegExp(urlRegex, 'i');
        return str.length < 2083 && url.test(str);
   }

    private static ValidUrlFallback(url: string) {
        // TODO: log if not valid
        return this.isURL(url || '') ? url : null;
    }

    public static RewriteUrlToDesiredPod(url: string, authenticated: boolean, userPod: string) {
        if (!this.isURL(url || '')) {
            return null;
        }

        const pod = (authenticated ? (userPod || 'na1') : '').toLowerCase();
        // create an anchor element so that we can extract the hostname and pathname from the url
        const anchor = document.createElement('a');
        anchor.href = url.toLowerCase();

        if (anchor.hostname === 'localhost') {
            return url;
        }

        // in IE, anchor.pathname will not start with a slash
        const path = anchor.pathname.indexOf('/') !== 0 ? '/' + anchor.pathname : anchor.pathname;
        const absolutePath = path + anchor.search + anchor.hash;
        let subdomain = anchor.hostname.split('.')[0];
        let originPart = '';
        const origin = 'origin-';
        if (subdomain.indexOf(origin) === 0) {
            subdomain = subdomain.substring(origin.length);
            originPart = origin;
        }
        const subdomainAndUrlArray = subdomain.split('-');
        // get everything but the last part
        const podlessSubdomain = subdomainAndUrlArray.length > 1 ? subdomainAndUrlArray.slice(0, subdomainAndUrlArray.length - 1).join('-') : subdomain;
        // get the pod from the url if there's at least one - character
        const urlPod = subdomainAndUrlArray.length > 1 ? subdomainAndUrlArray[subdomainAndUrlArray.length - 1].toLowerCase() : '';

        if (subdomain.indexOf('-') === -1 && authenticated) {
            let subDomainToRedirect = podlessSubdomain;

            if (path && path !== '/') {
                // strip leading slash, and take the first component
                subDomainToRedirect = path.substr(1).split('/')[0];
            }

            let poddedSubdomain = `${subDomainToRedirect}-${pod}`;
            if (anchor.hostname.indexOf('totalaccess.') === 0) {
                // TA uses pod subdomains (e.g. totalaccess.emarketer.com)
                poddedSubdomain = 'totalaccess';
            }

            let newPath = absolutePath.replace('/' + subDomainToRedirect, '');

            if (newPath.indexOf('/') !== 0) {
                newPath = '/' + newPath;
            }
            return PodService.ValidUrlFallback('https://' + originPart + poddedSubdomain + config.domainName + newPath);
        }

        if (!authenticated && podlessSubdomain !== 'www') {
            const publicUrl = config.publicUrl.indexOf('https://') === 0 ? config.publicUrl.replace('https://', 'https://' + originPart) :
                config.publicUrl.indexOf('http://') === 0 ? config.publicUrl.replace('http://', 'http://' + originPart) :
                originPart + config.publicUrl;

            return PodService.ValidUrlFallback(publicUrl + podlessSubdomain + absolutePath);

        }

        return PodService.ValidUrlFallback(urlPod === pod ? url : url.replace(urlPod, pod));
    }

    public static DoPodRedirect(enablePodRedirection: boolean, authenticated: boolean, currentPod: string, currentUrl: string, userPod: string) {
        // this method is a replacement for subscriptions/usergateway
        if (!enablePodRedirection) {
            return null;
        }
        const inRightPod = authenticated && PodService.IsUrlInRightPod(currentUrl, userPod);
        if ((authenticated && !inRightPod) || (!authenticated && currentPod !== ANONYMOUS_POD)) {
            return PodService.RewriteUrlToDesiredPod(currentUrl, authenticated, userPod);
        }
        return null;
    }
}
