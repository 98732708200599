import React, { Component } from 'react';
import NavigationMenuLink from '../NavigationMenuLink';
import config from 'config';

export default class Subscribe extends Component<
    {
        // props
    },
    {
        // state
    }
> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className="navigation-menu__subscribe">
                <ul className="navigation-menu__links">
                    <li>
                        <h3>Subscribe to EMARKETER</h3>
                    </li>
                    <NavigationMenuLink menuName="Subscribe" text={'Plans & Pricing'} url={`${config.emarketerServiceUrl}corporate/products-plans`} />
                    <NavigationMenuLink menuName="Subscribe" text={'Process'} url={`${config.emarketerServiceUrl}corporate/why-emarketer#tab=OurApproach`} />
                    <NavigationMenuLink menuName="Subscribe" text={'Why EMARKETER?'} url={`${config.emarketerServiceUrl}corporate/why-emarketer`} />
                    <NavigationMenuLink menuName="Subscribe" text={'Coverage'} url={`${config.emarketerServiceUrl}corporate/pro`} />
                    <NavigationMenuLink menuName="Subscribe" text={'Customer Stories'} url={`${config.emarketerServiceUrl}corporate/clients`} />
                </ul>
                <div className="navigation-menu__content">
                    <div className="request-a-quote">
                        <div className="headline -center">Powerful data and analysis on nearly every digital topic.</div>
                        <a
                            href={`${config.emarketerServiceUrl}corporate/request-quote-open-access`}
                            data-analytics="Branding-Navigation-Subscribe-RequestQuote"
                        >
                            <button>Request a Quote</button>
                        </a>
                    </div>
                    <div className="additional-links -center">
                        <NavigationMenuLink menuName="Subscribe" text={'See All Contact Info'} url={`${config.emarketerServiceUrl}about/contactus`} />
                    </div>
                </div>
            </div>
        );
    }
}
