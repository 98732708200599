import React, { Component } from 'react';
import NavigationMenuLink from '../../../../../components/Navigation/NavigationMenu/NavigationMenuLink';
import BuildServiceUrl from '../../../../../helpers/ServiceUrlBuilder';
import Loading from '../../../../Loading/Loading';
import config from '../../../../../config';

export default class FreeContent extends Component<
    {
        // props
        pod: string;
        renderContent: boolean;
    },
    {
        // state
    }
> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        const { pod } = this.props;

        return this.props.renderContent ? (
            <div className="mobile-page-section__free">
                <div className="mobile-page-section__hr-mini" />
                <div className="mobile-page-section__title">Daily News</div>
                <ul className="mobile-page-section__list -free">
                    <NavigationMenuLink menuName="Free-Mobile" text={'Articles'} url={BuildServiceUrl(pod, 'articles')} />
                    <NavigationMenuLink menuName="Free-Mobile" text={'Podcasts'} url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-podcast`} />
                    <NavigationMenuLink menuName="Free-Mobile" text={'Roundups'} url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-roundup`} />
                    <NavigationMenuLink menuName="Free-Mobile" text={'Videos'} url={`${BuildServiceUrl(pod, 'articles')}/videos`} />
                    <NavigationMenuLink menuName="Free-Mobile" text={'Webinars'} url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-webinar`} />
                </ul>
            </div>
        ) : null;
    }
}
