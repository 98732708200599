import React from 'react';

const AIIcon = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="31" height="31" rx="15.5" stroke="#111111" stroke-width="2"/>
        <path d="M16.784 21L16.21 19.264H11.954L11.366 21H9.098L12.626 11.06H15.608L19.15 21H16.784ZM15.594 17.388L15.216 16.268L14.894 15.316C14.8287 15.12 14.7027 14.756 14.516 14.224C14.3387 13.6827 14.1893 13.202 14.068 12.782C13.7973 13.762 13.424 14.9473 12.948 16.338L12.598 17.388H15.594ZM22.5582 11.06V21H20.2902V11.06H22.5582Z" fill="black"/>
    </svg>
);

export default AIIcon;
