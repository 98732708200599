import React, { Component } from 'react';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Section from '../../../../components/MobileMenu/Page/Section/Section';
import List from '../../../../components/MobileMenu/Page/Section/SectionTemplate/List';
import FreeContent from '../../../../components/MobileMenu/Page/Section/SectionTemplate/FreeContent';
import SubscribeToPro from '../../../../components/MobileMenu/Page/Section/SectionTemplate/SubscribeToPro';
import {
    advertisingMarketing,
    ecommerceRetail,
    moreIndustries,
    proPlus,
} from '../../../Navigation/NavigationMenu/MenuTemplates/Data';
import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import config from '../../../../config';

export default class NavigationPage extends Component<
    {
        // props
        authenticated: boolean;
        enablePodRedirection: boolean;
        pod: string;
        navOpen: boolean;
        enableIndustryKPIs?: boolean;
        hasFreemiumRole: boolean;
    },
    {}
> {
    public render() {
        const { authenticated, enablePodRedirection, pod, enableIndustryKPIs, hasFreemiumRole } = this.props;

        return (
            <div className="mobile-page__navigation">
                {!authenticated ? (
                    <Section>
                        <FreeContent renderContent={this.props.navOpen} pod={pod} />
                    </Section>
                ) : null}

                {!authenticated ? (
                    <Section>
                        <SubscribeToPro renderContent={this.props.navOpen} />
                    </Section>
                ) : null}

                <div className="mobile-page__search">
                    <SearchBar authenticated={authenticated} firstName={''} pod={pod} enablePodRedirection={enablePodRedirection} />
                </div>

                {/* PROMOTIONAL SECTION */}
                {/* <Section
                    title="Retail Media Networks"
                    url={`${BuildServiceUrl(pod, 'topics')}/category/Retail%20Media%20Networks`}
                    subUrl={`${BuildServiceUrl(pod, 'topics')}/category/Retail%20Media%20Networks`}
                    subTitle="See All"
                >
                    <div className="mobile-page__promo">
                        Discover the latest trends and insights in the world of Retail Media Networks with our comprehensive coverage.
                    </div>
                </Section> */}
                {/* PROMOTIONAL SECTION */}

                <Section
                    title={advertisingMarketing.name}
                    url={BuildServiceUrl(pod, 'topics')}
                    subTitle="See All"
                    subUrl={`${BuildServiceUrl(pod, 'topics')}/industry/${advertisingMarketing.slug}`}
                >
                    <List data={advertisingMarketing.children} type="Topics" pod={pod} renderContent={this.props.navOpen} />
                </Section>

                <Section
                    title="Ecommerce"
                    url={BuildServiceUrl(pod, 'topics')}
                    subTitle="See All"
                    subUrl={`${BuildServiceUrl(pod, 'topics')}/industry/${ecommerceRetail.slug}`}
                >
                    <List data={ecommerceRetail.children} type="Industries" pod={pod} renderContent={this.props.navOpen} />
                </Section>

                <Section
                    title={moreIndustries.name}
                    url={BuildServiceUrl(pod, 'topics')}
                    subTitle="See All"
                    subUrl={`${BuildServiceUrl(pod, 'topics')}#${encodeURIComponent('more industries_refid')}`}
                >
                    <List data={moreIndustries.children} type="Industries" pod={pod} renderContent={this.props.navOpen} />
                </Section>

                <Section title="Forecasts" url={`${BuildServiceUrl(pod, 'forecasts')}`} />

                {enableIndustryKPIs ? <Section title="Industry KPIs" url={BuildServiceUrl(pod, 'industry-kpis')} /> : null}

                <Section
                    title={proPlus.name}
                    url={`${config.emarketerBaseUrl}/products/pro-plus/`}
                    subTitle="See All"
                    subUrl={`${config.emarketerBaseUrl}/products/pro-plus/`}
                    target="_blank"
                >
                    <List data={proPlus.children} type={proPlus.name} pod={pod} renderContent={this.props.navOpen} />
                </Section>

                {hasFreemiumRole ? <Section title="Pricing" url={`${config.publicUrl}pricing`} target="_blank" /> : null}

                <Section title="Events" url={`${config.publicUrl}events`} target="_blank" />

                <Section title="Podcasts" url={`${BuildServiceUrl(pod, 'articles')}/topics/emarketer-podcast`} />
            </div>
        );
    }
}
