import React, { Component } from 'react';
import LoadRemoteComponent from '../../../components/LoadRemoteComponent/LoadRemoteComponent';
import Loading from '../../Loading/Loading';
import config from '../../../config';
import './Menu.scss';

export default class Menu extends Component<{
    // props
    active: boolean;
    title: string;
    enablePodRedirection: boolean;
    returnToCurrentServiceAfterRegistration: boolean;
    returnUrlQueryString: string;
    registrationSource: string;
}, {}> {

    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    public handleFormSubmit(e)  {
        e.preventDefault();
        return false;
    }

    public render() {

        const isMenuActive = this.props.active ? ' -active' : '';
        const loginProps = {
            disablePodRedirection: !this.props.enablePodRedirection,
            returnToCurrentServiceAfterRegistration: this.props.returnToCurrentServiceAfterRegistration,
            returnUrlQueryString: this.props.returnUrlQueryString,
            registrationSource: this.props.registrationSource,
        };

        return (
            <div className={`get-started-menu${isMenuActive}`}>
                <img className="get-started-menu__arrow" alt={'arrow'} src={require('./images/menu-arrow.png')} width="23" height="11"/>
                <div className="get-started-menu__modal">
                    <form className="get-started-menu__form" onSubmit={this.handleFormSubmit}>
                        <LoadRemoteComponent
                            url={config.subscriptionsDoesMyCompanySubscribePluginUrl}
                            name="loginorsignupcomponent-doesmycompanysubscribe"
                            props={loginProps}>
                                <Loading />
                        </LoadRemoteComponent>
                    </form>
                </div>
            </div>
        );
    }
}
