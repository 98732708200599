import React, { Component } from 'react';
import LoadRemoteComponent from '../../../LoadRemoteComponent/LoadRemoteComponent';
import Loading from '../../../Loading/Loading';
import config from '../../../../config';

export default class CalendarPage extends Component<{
    // props
    menuOpen: boolean;
}, {
    // state
}> {

    constructor(props) {
        super(props);
    }

    public render() {
        const { menuOpen } = this.props;

        return menuOpen ? (
            <div className="mobile-page__calendar">
                <React.Suspense fallback={<Loading />}>
                    <LoadRemoteComponent
                        url={config.noteworthyContentService}
                        name="noteworthycontent"
                        props={null}>
                            <Loading />
                    </LoadRemoteComponent>
                </React.Suspense>
            </div>
        ) : null;
    }
}
