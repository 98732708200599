import React, { Component } from 'react';
import FormatText from '../../../../helpers/FormatText';
import '../../../../theme/fontAwesome.scss';
import './MenuRow.scss';

interface IMenuRowProps {
    title: string;
    url?: string;
    icon?: string;
    svgIcon?: any;
    func?: any;
}

export default class MenuRow extends Component<IMenuRowProps, {}> {

    constructor(props) {
        super(props);
    }

    public render() {

        const { title, url, icon, func, svgIcon } = this.props;
        return (
            <a className={`user-menu-row`}
               href={url ? url : null}
               onClick={func ? func : null}
               data-analytics={`Branding-Tools-UserMenu-${FormatText(title)}`}>
                <div className={`user-menu-row__icon`}>
                    {icon ? <span className={icon} /> : null}
                    {svgIcon ? svgIcon : null}
                </div>
                <span className="user-menu-row__title">{title}</span>
            </a>
        );
    }
}
