import Cookies from 'js-cookie';

export const getCookie = (key: string): any => {
  return Cookies.get(key);
};

export const setCookie = (key: string, ttl: number, domain: string) => {
  Cookies.set(key, 'true', { path: '/', domain, expires: new Date(ttl)});
};

export const resetCookie = (key: string, domain: string) => {
  Cookies.set(key, 'true', { path: '/', domain, expires: -1});
};
