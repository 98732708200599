import React, { Component } from 'react';
import Loading from '../Loading/Loading';
import './UserCompanyLogin.scss';

// don't load menu until user interacts with it
const Menu = React.lazy(() =>
  import('./Menu/Menu'),
);

export default class UserCompanyLogin extends Component<{
    // props
    isTransparent: boolean;
    enablePodRedirection: boolean;
    returnToCurrentServiceAfterRegistration: boolean;
    returnUrlQueryString: string;
    registrationSource: string;
    scrolled: boolean;
}, {
    // state
    open: boolean;
    loginWrapperRef: any;
}> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loginWrapperRef: '',
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setGetStartedWrapperRef = this.setGetStartedWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setGetStartedWrapperRef(node) {
        this.setState({
            loginWrapperRef: node,
        });
    }

    private toggleMenu() {
        this.setState({
            open: !this.state.open,
        });
    }

    private closeMenu() {
        this.setState({ open: false });
    }

    private handleClickOutside(event) {
        if (this.state.open) {
            if (this.state.loginWrapperRef && !this.state.loginWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {

        const { isTransparent, scrolled } = this.props;

        const userCompanyLoginStyles = () => {
            const isS = this.props.scrolled ? ' -scrolled' : '';
            return `user-company-login${isS}`;
        };

        const buttonStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';
            return `user-company-login__button${isT}${isS}`;
        };

        return (
            <div className={`${userCompanyLoginStyles()}`} ref={this.setGetStartedWrapperRef}>
                <div className={buttonStyles()}
                     onClick={this.toggleMenu}
                     data-analytics="Branding-Tools-GetStarted">Does my company subscribe?</div>
                {
                    this.state.open ?
                    <React.Suspense fallback={<Loading />}>
                        <Menu
                            active={this.state.open}
                            title={''}
                            enablePodRedirection={this.props.enablePodRedirection}
                            returnToCurrentServiceAfterRegistration={this.props.returnToCurrentServiceAfterRegistration}
                            returnUrlQueryString={this.props.returnUrlQueryString}
                            registrationSource={this.props.registrationSource}
                        />
                    </React.Suspense> : null
                }
            </div>
        );
    }
}
