import React, { Component } from 'react';
import './Hamburger.scss';

interface IHamburgerProps {
    isTransparent: boolean;
    isActive: boolean;
}

export default class Hamburger extends Component<IHamburgerProps, {}> {

    public render() {

        const { isTransparent } = this.props;

        const hamburgerStyles = () => {
            const isActive = this.props.isActive ? 'is-active' : '';
            const type = 'hamburger--spin';
            return `hamburger ${type} ${isActive}`;
        };

        const hamburgerColor = () => {
            const isT = isTransparent ? 'hamburger-inner--white' : '';
            return `hamburger-inner ${isT}`;
        };

        return (
            <div className="navigation-hamburger">
                <button className={hamburgerStyles()} type="button">
                    <span className="hamburger-box">
                        <span className={hamburgerColor()}/>
                    </span>
                </button>
            </div>
        );
    }
}
