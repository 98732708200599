import React, { Component } from 'react';
import ChevronUp from '../../../theme/svgs/chevron-up';
import ChevronDown from '../../../theme/svgs/chevron-down';
import './NavigationItem.scss';

export default class NavigationItem extends Component<{
// props
    text: string;
    isTransparent: boolean;
    updateSelected: any;
    active: boolean;
    isDarkMode?: boolean;
    divider?: string;
    scrolled: boolean;
}, {
// state
}> {

    constructor(props) {
        super(props);
        this.updateSelected = this.updateSelected.bind(this);
    }

    public updateSelected() {
        this.props.updateSelected(this.props.text);
    }

    public render() {

        const { text, isTransparent, active, isDarkMode, divider, scrolled } = this.props;

        const itemStyles = () => {
            const isA = active ? ' -active' : '';
            const isT = isTransparent ? ' -transparent' : '';
            return `navigation-item${isA}${isT}`;
        };

        const titleStyles = () => {
            const isA = active ? ' -active' : '';
            const isT = isTransparent ? ' -transparent' : '';
            const isD = !isTransparent && isDarkMode ? ' -black' : '';
            const isS = scrolled ? ` -scrolled` : '';
            return `navigation-item__title${isA}${isT}${isD}${isS}`;
        };

        return (
            <div className={itemStyles()} onClick={this.updateSelected}>
                <div
                    onClick={this.updateSelected}
                    className={titleStyles()}
                    data-analytics={`Branding-Navigation-${text}-Menu`}>
                        {text}
                        {this.props.active ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
        );
    }
}
