import React, { Component } from 'react';
import './NavigationMenu.scss';

export default class NavigationMenu extends Component<{
// props
    active: boolean;
    menu: any;
    wide?: boolean;
}, {
// state
}> {

    public render() {

        const { active, menu, wide } = this.props;

        const classNames = () => {
            const isA = active ? ' -active' : '';
            const isW = wide ? ' -wide' : '';
            return `navigation-menu${isA}${isW}`;
        };

        return (
            <div className={classNames()}>
                {menu}
            </div>
        );
    }
}
