import React, { Component } from 'react';
import Menu from './Menu/Menu';
import User from '../../theme/svgs/user';
import '../../theme/fontAwesome.scss';
import './UserMenu.scss';

interface IUserMenuProps {
    authenticated: boolean;
    isTransparent: boolean;
    enablePodRedirection: boolean;
    isIpUser: boolean;
    userEmail: string;
    canReferAColleague: boolean;
    scrolled?: boolean;
}

interface IUserMenuState {
    open: boolean;
    userMenuWrapperRef: any;
}

export default class UserMenu extends Component<IUserMenuProps, IUserMenuState> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userMenuWrapperRef: '',
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setUserMenuWrapperRef = this.setUserMenuWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setUserMenuWrapperRef(node) {
        this.setState({
            userMenuWrapperRef: node,
        });
    }

    private toggleMenu() {
        this.setState({
            open: !this.state.open,
        });
    }

    private closeMenu() {
        this.setState({ open: false });
    }

    private handleClickOutside(event) {
        if (this.state.open) {
            if (this.state.userMenuWrapperRef && !this.state.userMenuWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {

        const { isIpUser, isTransparent, userEmail, canReferAColleague, scrolled } = this.props;

        const buttonStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-menu__button${isT}${isS}`;
        };

        const iconStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-menu__icon${isT}${isS}`;
        };
        const brandingUserStyles = () => {
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-user-menu${isS}`;
        };

        return (
            <div className={brandingUserStyles()}
                 ref={this.setUserMenuWrapperRef}>
                <div className={buttonStyles()}
                     onClick={this.toggleMenu}
                     data-analytics="Branding-Tools-UserMenu-Toggle">
                     <div className={`${iconStyles()} `}>
                        <User />
                     </div>
                </div>
                <Menu active={this.state.open}
                      isIpUser={isIpUser}
                      enablePodRedirection={this.props.enablePodRedirection}
                      userEmail={userEmail}
                      canReferAColleague={canReferAColleague} />
            </div>
        );
    }
}
