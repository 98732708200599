import { Component } from 'react';
import fetch from 'isomorphic-unfetch';
import config from 'config';
import Track from '../../helpers/AnalyticsTracker';
import Cookies from 'js-cookie';
import JwtReader from 'em-jwt-reader';

const canUseDOM = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args);
  } else {
    console.warn('Intercom not initialized yet');
  }
};

interface IIntercomProps {
  userId?: string;
  firstName?: string;
  email?: string;
  pod?: string;
  extranetId?: string;
}

interface IIntercomState {
    loaded: boolean;
    initialized: boolean;
    hasPremiumRole: boolean;
}

export default class Intercom extends Component<IIntercomProps, IIntercomState> {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      initialized: false,
      hasPremiumRole: JwtReader.IsInRole(Cookies.get('em_at'), 'ii_enhanced_forecast'),
    };
    window.intercomLoaded = this.intercomLoaded.bind(this);
  }

  private intercomLoaded() {
    this.setState({...this.state, loaded: true});
  }

  public componentDidMount() {
    if (canUseDOM && !window.Intercom && config.intercomAppId) {
        // load intercom plugin
        const interComScript = document.createElement('script');
        interComScript.type = 'text/javascript';
        interComScript.text = `(function () { var w = window; var ic = w.Intercom;
                if (typeof ic === \"function\") { ic("reattach_activator"); ic("update", intercomSettings); }
                else { var d = document; var i = function () { i.c(arguments) };
                i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i;
                function l() { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true;
                s.onload = window.intercomLoaded;
                s.src = 'https://widget.intercom.io/widget/${config.intercomAppId}';
                var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); } l(); } })()`;
        interComScript.defer = true;
        document.getElementsByTagName('head')[0].appendChild(interComScript);
    }
  }

  public componentDidUpdate() {
    const { userId, email, pod, firstName, extranetId } = this.props;
    const { initialized, loaded } = this.state;

    if (!config.intercomAppId || !canUseDOM || initialized) {
      return;
    }

    if (window.Intercom && !initialized) {
      window.Intercom('onShow', () => {
        Track('Intercom');
      });

      if (userId) {
        fetch(`${config.subscriptionsServiceUrl}/intercom`,
          {
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache',
            },
            method: 'POST',
          })
          .then((response) => response.json())
          .then((data) => {
            const hash = data.Hash;
            window.Intercom('boot',
              {
                app_id: config.intercomAppId,
                user_id: userId, name: firstName,
                email, user_hash: hash, pod,
                widget: { activator: '#IntercomDefaultWidget' },
                extranet_id: extranetId,
                hasPremiumRole: this.state.hasPremiumRole,
              });

            this.setState({ ...this.state, initialized: true });
          });
      } else {
        window.Intercom('boot',
          {
            app_id: config.intercomAppId,
            widget: { activator: '#IntercomDefaultWidget' },
          });

        this.setState({ ...this.state, initialized: true });
      }
    }
  }

  public componentWillUnmount() {
    if (!canUseDOM) {
        return false;
    }

    window.Intercom('shutdown');

    delete window.Intercom;
  }

  public render() {
    return (null);
  }
}
