import React, { Component } from 'react';
import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import FormatText from '../../../../helpers/FormatText';
import ChevronRight from '../../../../theme/svgs/chevron-right';
import ChevronLeft from '../../../../theme/svgs/chevron-left';

export default class DeepCascadeMenu extends Component<
    {
        // props
        data: {
            name: string;
            slug: string;
            children?: any[];
        };
        pod: string;
        type: string; // topic vs industry etc
        menuCloseFn?: any;
    },
    {
        // state
        selectedTopic: any;
        selectedTopicSlug: any;
        selectedDataArr: any[];
        menuLevels: string[];
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedTopic: this.props.data.name,
            selectedTopicSlug: this.props.data.slug,
            selectedDataArr: this.props.data.children,
            menuLevels: [],
        };
        this.findParentInTreeGoBack = this.findParentInTreeGoBack.bind(this);
        this.isSpecialCaseUrl = this.isSpecialCaseUrl.bind(this);
    }

    public findParentInTreeGoBack(object, parentName) {
        const result = [];
        // if we hit the root of the tree
        if (parentName === this.props.data.name) {
            const newMenuDepth = [];
            this.setState({
                selectedTopic: this.props.data.name,
                selectedTopicSlug: this.props.data.slug,
                selectedDataArr: this.props.data.children,
                menuLevels: newMenuDepth,
            });
            return;
        }

        // search tree for parent topic
        if (object.hasOwnProperty('name') && object.name === parentName) {
            result.push(object);
            const updatedMenuDepth = this.state.menuLevels;
            updatedMenuDepth.splice(updatedMenuDepth.indexOf(parentName), 1);

            this.setState({
                selectedTopic: result[0].name,
                selectedTopicSlug: result[0].slug,
                selectedDataArr: result[0].children,
                menuLevels: updatedMenuDepth,
            });
            return;
        }
        // WE NEED TO GO DEEPER
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(object).length; i++) {
            if (typeof object[Object.keys(object)[i]] === 'object') {
                this.findParentInTreeGoBack(object[Object.keys(object)[i]], parentName);
            }
        }
    }

    public isSpecialCaseUrl(slug) {
        // cover all special cases in programmatically generated urls
        let url = '';
        const isFinancialVertical = this.state.menuLevels.indexOf('Financial Services') > -1;
        const subTopics = isFinancialVertical ? '?subTopics=financial-services' : '';
        switch (slug) {
            // A&M
            case 'AI%20in%20Marketing':
                url = `/category/${slug}`;
                break;
            case 'advertising-marketing':
                url = `/industry/${slug}`;
                break;

            // FS
            case 'payments':
            case 'checkout':
            case 'credit-debit':
            case 'digital-payments':
            case 'ecommerce':
            case 'mobile-payments':
            case 'transfers':
                url = `/industry/${slug}${subTopics}`;
                break;

            // Ecommerce
            case 'AI%20in%20Commerce':
                url = `/category/${slug}`;
                break;

            // Other
            case 'more-industries':
                url = `#${encodeURIComponent('more industries_refid')}`;
                break;

            default:
                url = '';
                break;
        }
        return url;
    }

    public render() {
        const { data, pod, type, menuCloseFn } = this.props;

        const getLink = (slug) => {
            return this.isSpecialCaseUrl(slug)
                ? `${BuildServiceUrl(pod, 'topics')}${this.isSpecialCaseUrl(slug)}`
                : `${BuildServiceUrl(pod, 'topics')}/${type}/${slug}`;
        };

        const selectTopic = (name, slug, children) => {
            if (!children || children.length < 1) {
                return;
            }
            const updatedMenuDepth = this.state.menuLevels;
            if (updatedMenuDepth.indexOf(this.state.selectedTopic) === -1) {
                updatedMenuDepth.push(this.state.selectedTopic);
            }
            this.setState({
                selectedTopic: name,
                selectedTopicSlug: slug,
                selectedDataArr: children,
                menuLevels: updatedMenuDepth,
            });
        };

        const topics = this.state.selectedDataArr.map((topic, index) => {
            return (
                <li
                    className="cascade-menu-list-item"
                    key={index}
                    onClick={() => selectTopic(topic.name, topic.slug, topic.children)}
                    data-analytics={`Branding-Navigation-${FormatText(topic.name)}`}
                >
                    {topic.children && topic.children.length >= 1 ? (
                        <>
                            {topic.name}
                            <ChevronRight />
                        </>
                    ) : (
                        <a href={getLink(topic.slug)}>{topic.name}</a>
                    )}
                </li>
            );
        });

        const goBack = () => {
            const parentName = this.state.menuLevels[this.state.menuLevels.length - 1];
            this.findParentInTreeGoBack(data, parentName);
        };

        const seeAllLink =
            this.state.selectedTopic === this.props.data.name
                ? this.isSpecialCaseUrl(this.props.data.slug)
                    ? `${BuildServiceUrl(pod, 'topics')}${this.isSpecialCaseUrl(data.slug)}`
                    : `${BuildServiceUrl(pod, 'topics')}/${type}/${data.slug}`
                : this.isSpecialCaseUrl(this.state.selectedTopicSlug)
                ? `${BuildServiceUrl(pod, 'topics')}${this.isSpecialCaseUrl(this.state.selectedTopicSlug)}`
                : `${BuildServiceUrl(pod, 'topics')}/${this.props.type}/${this.state.selectedTopicSlug}`;

        const anchorLinkSlugList = ['more-industries'];
        const flaggedTopicSlugsNoSeeAll = [];

        return (
            <div className="navigation-menu__cascade-menu-wrapper">
                <h2 className="cascade-menu-title">
                    {data.name === this.state.selectedTopic ? null : (
                        <div
                            data-analytics={`Branding-Navigation-${this.state.selectedTopic}-BackButton`}
                            onClick={() => goBack()}
                            className="cascade-menu-back"
                        >
                            <ChevronLeft />
                        </div>
                    )}
                    {this.state.selectedTopic}
                    {flaggedTopicSlugsNoSeeAll.indexOf(this.state.selectedTopicSlug) > -1 ? null : (
                        <a
                            data-analytics={`Branding-Navigation-${this.state.selectedTopic}-SeeAll`}
                            href={seeAllLink}
                            onClick={anchorLinkSlugList.indexOf(this.state.selectedTopicSlug) > -1 ? menuCloseFn : null}
                            className="cascade-menu-link"
                        >
                            See All
                        </a>
                    )}
                </h2>
                <div className="cascade-menu-subtitle">{this.state.selectedTopic === data.name ? 'All Topics' : 'All Subtopics'}</div>
                <ul className={`cascade-menu-list${topics.length > 12 ? ' long' : ''}`}>{topics}</ul>
            </div>
        );
    }
}
