import React, { Component } from 'react';
import LoadRemoteComponent from '../LoadRemoteComponent/LoadRemoteComponent';
import Track from '../../helpers/AnalyticsTracker';
import BuildServiceUrl from '../../helpers/ServiceUrlBuilder';
import config from '../../config';
import './SearchBar.scss';
import '../../theme/fontAwesome.scss';

export default class SearchBar extends Component<{
    // props
    authenticated: boolean;
    isTransparent?: boolean;
    isCorporate?: boolean;
    pod: string;
    enablePodRedirection: boolean;
    firstName: string;
    scrolled?: boolean;
}, {
    // state
    searchTerm: string;
    mobile: boolean;
    typeaheadLoaded: boolean;
}> {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            mobile: false,
            typeaheadLoaded: false,
        };
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
        this.search = this.search.bind(this);
        this.loadTypeahead = this.loadTypeahead.bind(this);
    }

    public componentDidMount() {
        const isMobileWindow = window.innerWidth < 1280 ? true : false;
        this.setState({
            mobile: isMobileWindow ? true : false,
        });
    }

    private search(e) {
        if (typeof e === 'string') {
            window.location.href  = `${BuildServiceUrl(this.props.pod, 'search1')}/?query=${encodeURIComponent(e)}`;
        } else {
            if (this.state.searchTerm) {
                window.location.href  =
                `${BuildServiceUrl(this.props.pod, 'search1')}/?query=${encodeURIComponent(this.state.searchTerm)}`;
            }
            e.preventDefault();
        }
        Track(`Tools-Search-${this.state.searchTerm}`);
        return false;
    }

    private handleSearchTerm(val, triggerSearch) {
        this.setState({
            searchTerm: val,
        });
        if (triggerSearch) {
            this.search(val);
        }
    }

    private loadTypeahead() {
        this.setState({
            typeaheadLoaded: true,
        });
        const checkExist = setInterval(() => {
            const input: HTMLElement = document.querySelector('#emarketer-typeahead-host > div > div > input');
            if (input) {
                console.log('Exists!');
                clearInterval(checkExist);
                input.focus();
            }
         }, 100);
    }

    public render() {

        const { authenticated, isCorporate, isTransparent, scrolled } = this.props;

        const stylesIfScrolled = () => {
            const isS = scrolled ? ' -scrolled' : '';
            return `${isS}`;
        };

        const searchMenuStyles = () => {
            const isPro = authenticated && !isCorporate ? ' -pro' : '';
            const isT = isTransparent ? ' -transparent' : '';
            const checkForScrolled = stylesIfScrolled();
            return `search-menu${isPro}${isT}${checkForScrolled}`;
        };

        const greeting = this.props.firstName ? `Hi ${this.props.firstName}, search` : 'Search';
        const placeholderText = this.state.mobile ? 'Search' : `${greeting} for marketing, finance, tech, & healthcare info`;

        const iconActive = this.state.searchTerm ? ' -active' : '';
        const typeAheadProps = {
            inputvalue: this.state.searchTerm,
            onChangeFunc: this.handleSearchTerm,
            className: `search-menu__input${stylesIfScrolled()}`,
            placeHolder: placeholderText,
            wrapperClassName: 'search-menu__input-wrapper',
        };

        return (
            <div className={searchMenuStyles()}>
                <form className="search-menu__form" onSubmit={this.search}>
                    <div id="emarketer-typeahead-host" style={{ height: '45px' }} >
                    {
                        !this.state.typeaheadLoaded ?
                        <input className={`search-menu__input${stylesIfScrolled()}`} onClick={this.loadTypeahead} placeholder={placeholderText} onFocus={this.loadTypeahead} aria-label="Search"/>
                        : null
                    }
                    {
                        this.state.typeaheadLoaded ?
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <LoadRemoteComponent props={typeAheadProps} url={config.TypeaheadPluginUrl} name="typeahead">
                                <input className={`search-menu__input${stylesIfScrolled()}`} onChange={(event) => this.handleSearchTerm(event.target.value, false)} aria-label="Search"/>
                            </LoadRemoteComponent>
                        </React.Suspense>
                        : null
                    }
                    </div>
                    <i className={`search-menu__mag${iconActive} icon-search${stylesIfScrolled()}`} onClick={this.search} data-analytics="Branding-Tools-Search-Icon-Submit" />
                    <button
                        className={`search-menu__submit${stylesIfScrolled()}`}
                        type="submit"
                        data-analytics="Branding-Tools-Search-Submit">
                            <span>Search</span>
                            <i className="icon-search" />
                    </button>
                </form>
            </div>
        );
    }
}
