import React, { Component } from 'react';
import User from '../../../theme/svgs/user';
import './IconButton.scss';

export default class IconButton extends Component<{
    // props
    icon: any;
    isTransparent: boolean;
}, {}> {

    public render() {

        const {icon, isTransparent } = this.props;

        const buttonStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            return `navigation-icon-button${isT}`;
        };

        const iconStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            return `navigation-icon-button__icon${isT}`;
        };

        return (
            <div className={buttonStyles()}>
                    <div className={`${iconStyles()} `}>
                        {icon}
                    </div>
            </div>
        );
    }
}
