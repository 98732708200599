// polyfill fetch
require('whatwg-fetch');

let proHomeServiceUrl = '#{PRO_HOME_SERVICE_URL}';
if (proHomeServiceUrl.substring(1) === '{PRO_HOME_SERVICE_URL}') {
  proHomeServiceUrl = 'https://www.preview1.emarketer.com';
}

let articlesServiceUrl = '#{ARTICLES_SERVICE_URL}';
if (articlesServiceUrl.substring(1) === '{ARTICLES_SERVICE_URL}') {
  articlesServiceUrl = 'https://articles.preview1.emarketer.com';
}

let brandingServiceUrl = '#{BRANDING_SERVICE_URL}';
if (brandingServiceUrl.substring(1) === '{BRANDING_SERVICE_URL}') {
  brandingServiceUrl = 'http://localhost:41337';
  // brandingServiceUrl = 'http://localhost:9021';
  // brandingServiceUrl = 'https://branding.preview1.emarketer.com';
}

let countriesServiceUrl = '#{COUNTRIES_SERVICE_URL}';
if (countriesServiceUrl.substring(1) === '{COUNTRIES_SERVICE_URL}') {
  countriesServiceUrl = 'https://www.emarketer.com/coverage';
}

let emarketerServiceUrl = '#{EMARKETER_SERVICE_URL}';
if (emarketerServiceUrl.substring(1) === '{EMARKETER_SERVICE_URL}') {
  emarketerServiceUrl = 'https://www.preview1.emarketer.com/';
}

let emarketerBaseUrl = '#{EMARKETER_BASE_URL}';
if (emarketerBaseUrl.substring(1) === '{EMARKETER_BASE_URL}') {
  emarketerBaseUrl = 'https://www.emarketer.com';
}

let emarketerProServiceUrl = '#{EMARKETER_PRO_SERVICE_URL}';
if (emarketerProServiceUrl.substring(1) === '{EMARKETER_PRO_SERVICE_URL}') {
  emarketerProServiceUrl = 'https://totalaccess.preview1.emarketer.com';
}

let numbersServiceUrl = '#{NUMBERS_SERVICE_URL}';
if (numbersServiceUrl.substring(1) === '{NUMBERS_SERVICE_URL}') {
  numbersServiceUrl = 'https://www.preview1.emarketer.com/forecasts/';
}

let intercomAppId = '#{INTERCOM_APP_ID}';
if (intercomAppId.substring(1) === '{INTERCOM_APP_ID}') {
  intercomAppId = 'hjr1c1ff';
}

let pod = '#{POD}';
if (pod.substring(1) === '{POD}') {
  pod = 'NAX';
}

let profileServiceUrl = '#{PROFILE_SERVICE_URL}';
if (profileServiceUrl.substring(1) === '{PROFILE_SERVICE_URL}') {
  profileServiceUrl = 'https://profile.preview1.emarketer.com/ProfileModal/showProfileModal.js';
}

let jobCodeServiceUrl = '#{JOBCODE_SERVICE_URL}';
if (jobCodeServiceUrl.substring(1) === '{JOBCODE_SERVICE_URL}') {
  jobCodeServiceUrl = 'https://jobcode.preview1.emarketer.com/JobCodeModal/dist/jobcode.js';
}

let reportsServiceUrl = '#{REPORTS_SERVICE_URL}';
if (reportsServiceUrl.substring(1) === '{REPORTS_SERVICE_URL}') {
  reportsServiceUrl = `${proHomeServiceUrl}/reports`;
}

let researchTopicsServiceUrl = '#{RESEARCH_TOPICS_SERVICE_URL}';
if (researchTopicsServiceUrl.substring(1) === '{RESEARCH_TOPICS_SERVICE_URL}') {
  researchTopicsServiceUrl = 'https://www.preview1.emarketer.com/topics';
}

let answersUrl = '#{ANSWERS_URL}';
if (answersUrl.substring(1) === '{ANSWERS_URL}') {
  answersUrl = 'https://www.preview1.emarketer.com/answers';
}

let searchHostUrl = '#{SEARCH_HOST_URL}';
if (searchHostUrl.substring(1) === '{SEARCH_HOST_URL}') {
  searchHostUrl = 'https://www.preview1.emarketer.com/search1/';
}

let subscriptionsSimpleLoginPluginUrl = '#{SUBSCRIPTIONS_SIMPLE_LOGIN_PLUGIN_URL}';
if (subscriptionsSimpleLoginPluginUrl.substring(1) === '{SUBSCRIPTIONS_SIMPLE_LOGIN_PLUGIN_URL}') {
  subscriptionsSimpleLoginPluginUrl = 'https://subscriptions.emarketer.com/loginorsignupcomponent/build/loginorsignupcomponent-topnavbare.js';
  // 'http://localhost:9001/loginorsignupcomponent/build/loginorsignupcomponent-topnavbare.js';
}

let subscriptionsDoesMyCompanySubscribePluginUrl = '#{SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL}';
if (subscriptionsDoesMyCompanySubscribePluginUrl.substring(1) === '{SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL}') {
  subscriptionsDoesMyCompanySubscribePluginUrl = 'https://subscriptions.emarketer.com/loginorsignupcomponent/build/loginorsignupcomponent-doesmycompanysubscribe.js';
  // 'http://localhost:9001/loginorsignupcomponent/build/loginorsignupcomponent-doesmycompanysubscribe.js';
}

let subscriptionsServiceUrl = '#{SUBSCRIPTIONS_SERVICE_URL}';
if (subscriptionsServiceUrl.substring(1) === '{SUBSCRIPTIONS_SERVICE_URL}') {
  subscriptionsServiceUrl = 'https://subscriptions.emarketer.com';
  // subscriptionsServiceUrl = 'http://localhost:9001';
}

let TypeaheadPluginUrl = '#{TYPEAHEAD_PLUGIN_URL}';
if (TypeaheadPluginUrl.substring(1) === '{TYPEAHEAD_PLUGIN_URL}') {
  TypeaheadPluginUrl = 'https://typeahead.preview1.emarketer.com/app/build/typeahead.js';
  // TypeaheadPluginUrl = 'http://localhost:18088/App/build/typeahead.js';
}

let domainName = '#{DomainName}';
if (domainName.substring(1) === '{DomainName}') {
  domainName = '.preview1.emarketer.com';
}

let publicUrl = '#{PublicUrl}';
if (publicUrl.substring(1) === '{PublicUrl}') {
  publicUrl = 'https://www.preview1.emarketer.com/';
}

let maxTokenRemainingLifeInMinutesStr = '#{MaxTokenRemainingLifeInMinutes}';
if (maxTokenRemainingLifeInMinutesStr.substring(1) === '{MaxTokenRemainingLifeInMinutes}') {
  maxTokenRemainingLifeInMinutesStr = '30';
}

let IsIntercomEnabledForAnonymous = '#{INTERCOM_ENABLED_FOR_ANONYMOUS}';
if (IsIntercomEnabledForAnonymous.substring(1) === '{INTERCOM_ENABLED_FOR_ANONYMOUS}') {
  IsIntercomEnabledForAnonymous = 'false';
}

let iForgotService = '#{iForgot_Service}';
if (iForgotService.substring(1) === '{iForgot_Service}') {
  iForgotService = 'https://iforgot.emarketer.com/';
}

const maxTokenRemainingLifeInMinutes = parseInt(maxTokenRemainingLifeInMinutesStr, 10);

let noteworthyContentService = '#{NOTEWORTHY_CONTENT_SERVICE}';
if (noteworthyContentService.substring(1) === '{NOTEWORTHY_CONTENT_SERVICE}') {
  // noteworthyContentService = 'https://noteworthy.preview1.emarketer.com/noteworthycontent.js';
  noteworthyContentService = 'http://10.16.12.162:8000/build/noteworthycontent.js';
}

let enableCalendarWidget = '#{ENABLE_NOTEWORTHY_CONTENT}';
if (enableCalendarWidget.substring(1) === '{ENABLE_NOTEWORTHY_CONTENT}') {
  enableCalendarWidget = true;
}

let noteworthyContentApiUrl = '#{NOTEWORTHY_CONTENT_API_URL}';
if (noteworthyContentApiUrl.substring(1) === '{NOTEWORTHY_CONTENT_API_URL}') {
  noteworthyContentApiUrl = 'https://noteworthycontentapi.preview1.emarketer.com/'; // test gql api
}

let alertsServiceUrl = '#{ALERTS_SERVICE_URL}';
if (alertsServiceUrl.substring(1) === '{ALERTS_SERVICE_URL}') {
  alertsServiceUrl = 'https://alerts-na1.preview1.emarketer.com';
}

let doesMyCompanySubURL = '#{DOES_MY_COMPANY_SUB_URL}';
if (doesMyCompanySubURL.substring(1) === '{DOES_MY_COMPANY_SUB_URL}') {
  doesMyCompanySubURL = 'https://www.preview1.emarketer.com/does-my-company-subscribe';
}

let enableIndustryKPIs = '#{ENABLE_INDUSTRY_KPIS}';
if (enableIndustryKPIs.substring(1) === '{ENABLE_INDUSTRY_KPIS}') {
  enableIndustryKPIs = true;
}

let favoritesServiceUrl = '#{FAVORITES_SERVICE_URL}';
if (favoritesServiceUrl.substring(1) === '{FAVORITES_SERVICE_URL}') {
  favoritesServiceUrl = 'https://favorites.preview1.emarketer.com/';
}

let cookieDomain = '#{COOKIE_DOMAIN}';
if (cookieDomain.substring(1) === '{COOKIE_DOMAIN}') {
  cookieDomain = '.emarketer.com';
}

let fontCssUrl = '#{COMMON_FONTS_CSS_URL}';
if (fontCssUrl.substring(1) === '{COMMON_FONTS_CSS_URL}') {
  fontCssUrl = 'https://fonts.emarketer.com/fonts.css';
}


let freemiumUserRegistrationLibraryUrl = '#{FREEMIUM_USERREGISTRATION_LIBRARY_URL}';
if (freemiumUserRegistrationLibraryUrl.substring(1) === '{FREEMIUM_USERREGISTRATION_LIBRARY_URL}') {
  freemiumUserRegistrationLibraryUrl = 'https://www.preview1.emarketer.com/userregistration/dist/index.js';
}

let freemiumExtranetId = '#{COMMON_FREEMIUM_EXTRANET_ID}';
if (freemiumExtranetId.substring(1) === '{COMMON_FREEMIUM_EXTRANET_ID}') {
  freemiumExtranetId = '12857';
}

let contentPersonalizationAPI = '#{COMMON_CONTENT_PERSONALIZATION_API}';
if (contentPersonalizationAPI.substring(1) === '{COMMON_CONTENT_PERSONALIZATION_API}') {
  contentPersonalizationAPI = 'https://contentp13n-api-na1.preview1.emarketer.com';
}

module.exports = {
  proHomeServiceUrl, // needs to be first
  articlesServiceUrl,
  brandingServiceUrl,
  TypeaheadPluginUrl,
  countriesServiceUrl,
  emarketerBaseUrl,
  emarketerServiceUrl,
  emarketerProServiceUrl,
  numbersServiceUrl,
  intercomAppId,
  pod,
  profileServiceUrl,
  jobCodeServiceUrl,
  reportsServiceUrl,
  researchTopicsServiceUrl,
  answersUrl,
  searchHostUrl,
  subscriptionsDoesMyCompanySubscribePluginUrl,
  subscriptionsSimpleLoginPluginUrl,
  subscriptionsServiceUrl,
  domainName,
  publicUrl,
  maxTokenRemainingLifeInMinutes,
  IsIntercomEnabledForAnonymous,
  iForgotService,
  noteworthyContentService,
  enableCalendarWidget,
  noteworthyContentApiUrl,
  alertsServiceUrl,
  enableIndustryKPIs,
  cookieDomain,
  doesMyCompanySubURL,
  favoritesServiceUrl,
  fontCssUrl,
  freemiumUserRegistrationLibraryUrl,
  freemiumExtranetId,
  contentPersonalizationAPI
};
