import React, { Component } from 'react';
import BuildServiceUrl from '../../../../helpers/ServiceUrlBuilder';
import config from '../../../../config';

export default class PROPlus extends Component<
{
    // props
    pod: string,
    enableIndustryKPIs: boolean,
}, {
    // state
}> {

    constructor(props: any) {
        super(props);
    }

    public render() {

        const { pod, enableIndustryKPIs } = this.props;

        return (
            <div className="navigation-menu__cascade-menu-wrapper">

                <h2 className="cascade-menu-title">PRO+
                    <a data-analytics={`Branding-Navigation-PRO+-SeeAll`} href={`${config.emarketerBaseUrl}/products/pro-plus/`} className="cascade-menu-link" target="_blank">See All</a>
                </h2>

                <div className="cascade-menu-subtitle" />

                <ul className="cascade-menu-list">
                    <li className="cascade-menu-list-item" data-analytics="Branding-Navigation-PRO+-Forecasts"><a href={BuildServiceUrl(pod, 'forecasts')}>Forecasts</a></li>
                    {enableIndustryKPIs ? <li className="cascade-menu-list-item" data-analytics="Branding-Navigation-PRO+-IndustryKPIs"><a href={BuildServiceUrl(pod, 'industry-kpis')}>Industry KPIs</a></li> : null}
                    <li className="cascade-menu-list-item" data-analytics="Branding-Navigation-PRO+-CompareForecasts"><a href={`${BuildServiceUrl(pod, 'premium-forecasts')}/metric/5,66,355,402,2625,2631,8700`}>Compare Forecasts</a></li>
                    <li className="cascade-menu-list-item" data-analytics="Branding-Navigation-PRO+-BuildForecastComparison"><a href={BuildServiceUrl(pod, 'premium-forecasts')}>Build a Forecast Comparison</a></li>
                    <li className="cascade-menu-list-item" data-analytics="Branding-Navigation-PRO+-HistoricalForecasts"><a href={`${config.emarketerBaseUrl}/products/pro-plus/#historical-forecasts`} target="_blank">Historical Forecasts</a></li>
                </ul>
            </div>
        );
    }
}
