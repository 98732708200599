import React, { Component } from 'react';
import config from '../../config';
import Intercom from '../../components/Intercom/Intercom';
import Logo from '../../components/Logo/Logo';
import SearchBar from '../../components/SearchBar/SearchBar';
import Navigation from '../../components/Navigation/Navigation';
import CalendarWidget from '../../components/CalendarWidget/CalendarWidget';
import UserFavorites from '../../components/UserFavorites/UserFavorites';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserLogin from '../../components/UserLogin/UserLogin';
import UserCompanyLogin from '../../components/UserCompanyLogin/UserCompanyLogin';
import UserPromoMenu from '../../components/UserPromoMenu/UserPromoMenu';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import Subscriptions from '../../helpers/Subscriptions';
import LoadScript from '../../helpers/LoadScript';
import DetectIE from '../../helpers/DetectIE';
import IEDeprecationBanner from '../../components/IEDeprecationBanner/IEDeprecationBanner';
import FreemiumOnboarding from '../../components/FreemiumOnboarding/FreemiumOnboarding';

import Cookies from 'js-cookie';

// styles
import './Branding.scss';

interface IBrandingProps {
    isTransparent?: boolean;
    enablePodRedirection?: boolean;
    returnToCurrentServiceAfterRegistration?: boolean;
    returnUrlQueryString?: string;
    registrationSource?: string;
    showSearch?: boolean;
    showNavigationMenu?: boolean;
    corporate?: boolean;
    enableStickyNav?: boolean;
}

interface IBrandingState {
    authenticated: boolean;
    isIpUser: boolean;
    userId: string;
    email: string;
    pod: string;
    intercomPod: string;
    firstName: string;
    intercomEligible: boolean;
    displayBranding: boolean;
    enablePodRedirection: boolean;
    shouldRender: boolean;
    referAColleague: boolean;
    requireCookieAcceptance: boolean;
    requireIEDeprecationBanner: boolean;
    menuActive: boolean;
    scrolled: boolean;
    stickySearchBarDisplay: boolean;
    enableSticky: boolean;
    searchBarRef: any;
    screenWidth: number;
    extranetId: string;
    hasFreemiumRole: boolean;
}

/* tslint:disable interface-name */
declare module 'react' {
    interface LinkHTMLAttributes<T> {
        as?: string;
    }
}
/* tslint:enable interface-name */

export default class Branding extends Component<IBrandingProps, IBrandingState> {
    public state = {
        authenticated: false,
        isIpUser: false,
        loginTypeId: '1',
        pod: '',
        intercomEligible: false,
        displayBranding: false,
        enablePodRedirection: false,
        shouldRender: false,
        userId: '0',
        email: '',
        intercomPod: '',
        firstName: '',
        referAColleague: false,
        requireCookieAcceptance: false,
        requireIEDeprecationBanner: false,
        menuActive: false,
        scrolled: false,
        stickySearchBarDisplay: false,
        enableSticky: true,
        searchBarRef: null,
        screenWidth: window.innerWidth,
        extranetId: '',
        hasFreemiumRole: false,
    };

    constructor(props) {
        super(props);

        const enablePodRedirection = this.props.enablePodRedirection ? this.props.enablePodRedirection : false;
        const { redirected, accessToken, referAColleague, hasFreemiumRole } = Subscriptions.Init(enablePodRedirection);

        this.updateMenuSelected = this.updateMenuSelected.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.updateFixedSearchBarDisplay = this.updateFixedSearchBarDisplay.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setsearchBarRef = this.setsearchBarRef.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        if (redirected) {
            return;
        }

        const userId = parseInt(accessToken.userId, 10) || 0;
        const acceptCookiesTokenExists = Cookies.get('em_gdpr_allowcookies') !== undefined;
        const showIEDeprecationBanner = DetectIE();
        this.state = {
            ...this.state,
            enablePodRedirection,
            shouldRender: true,
            displayBranding: true,
            intercomPod: accessToken.pod,
            intercomEligible: userId > 0,
            authenticated: accessToken.authenticated,
            email: accessToken.email,
            firstName: accessToken.firstName,
            isIpUser: accessToken.isIpUser,
            loginTypeId: accessToken.loginTypeId,
            pod: accessToken.pod,
            userId: accessToken.userId,
            referAColleague,
            requireCookieAcceptance: acceptCookiesTokenExists === false,
            requireIEDeprecationBanner: showIEDeprecationBanner,
            menuActive: false,
            stickySearchBarDisplay: false,
            enableSticky: this.props.enableStickyNav ? true : false,
            searchBarRef: null,
            screenWidth: window.innerWidth,
            extranetId: accessToken.extranetId,
            hasFreemiumRole,
        };
    }

    public shouldComponentUpdate(nextProps: IBrandingProps, nextState: IBrandingState) {
        return this.state.shouldRender;
    }

    public componentDidMount() {
        if (typeof window !== 'undefined' && window.document && window.document.createElement) {

            if (!this.state.hasFreemiumRole) {
              if (!window.eMarketer_Profile_ModalDialog_Service && !window.eMarketerProfileServiceLoading && !this.props.corporate && config.pod !== 'NAX') {
                window.eMarketerProfileServiceLoading = true;
                LoadScript(config.profileServiceUrl);
              }

              if (
                config.jobCodeServiceUrl.length > 0 &&
                !window.eMarketer_JobCode_ModalDialog_Service &&
                !window.eMarketerJobCodeServiceLoading &&
                !this.props.corporate &&
                config.pod !== 'NAX'
            ) {
                window.eMarketerJobCodeServiceLoading = true;
                LoadScript(config.jobCodeServiceUrl);
            }
          }
        }

        window.addEventListener('resize', this.updateWindowDimensions);
        document.addEventListener('scroll', this.handleScroll);
        document.addEventListener('mousedown', this.handleClickOutside);
        this.handleScroll();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    public updateMenuSelected(s) {
        this.setState({
            menuActive: s,
        });
    }

    public updateFixedSearchBarDisplay(s) {
        this.setState({
            stickySearchBarDisplay: s,
        });
    }

    public handleScroll() {
        if (window.pageYOffset > 50 && this.state.scrolled === false) {
            if (this.state.enableSticky) {
                this.setState({
                    scrolled: true,
                });
            }
        }
        if (window.pageYOffset < 50 && this.state.scrolled === true) {
            this.setState({
                scrolled: false,
            });
        }
    }

    private setsearchBarRef(node) {
        this.setState({
            searchBarRef: node,
        });
    }

    private closeMenu() {
        this.setState({ stickySearchBarDisplay: false });
    }

    private handleClickOutside(event) {
        if (this.state.stickySearchBarDisplay) {
            if (this.state.searchBarRef && !this.state.searchBarRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {
        const brandingServiceCssUrl = `${config.brandingServiceUrl}/app/6.0/build/branding.css`;

        const interComSection = this.state.isIpUser ? null : this.state.intercomEligible ? (
            <Intercom
                userId={this.state.userId}
                firstName={this.state.firstName}
                pod={this.state.intercomPod}
                email={this.state.email}
                extranetId={this.state.extranetId}
            />
        ) : config.IsIntercomEnabledForAnonymous !== 'false' ? (
            <Intercom />
        ) : null;

        const requireIEDeprecationBannerSection = this.state.requireIEDeprecationBanner ? <IEDeprecationBanner /> : null;

        const freemiumOnboardingPopup = () => {
          const demographicInfoStep  = '3' ;
          if (typeof window !== 'undefined' && window.document && this.state.hasFreemiumRole) {

            const freemiumStepsRemaining = Cookies.get('em_fosr');

            if (freemiumStepsRemaining && freemiumStepsRemaining === demographicInfoStep) {
                return <FreemiumOnboarding  userId={this.state.userId}/>;
            }
          }
          return null;
        };

        const brandingStyles = () => {
            const isBrandingTransparent = isTransparent ? ' -transparent' : '';
            const isDisplayed = this.state.displayBranding ? ' -displayed' : '';
            const isScrolled = this.state.scrolled && this.state.enableSticky ? ' -scrolled' : '';

            return `branding${isBrandingTransparent}${isDisplayed}${isScrolled}`;
        };
        const brandingSearchStyles = () => {
            const isFixedSearchBarDisplay = this.state.scrolled ? (this.state.stickySearchBarDisplay ? ' -visible' : ' -hidden') : ' -visible';
            const isScrolled = this.state.scrolled && this.state.enableSticky ? ' -scrolled' : '';
            return `branding__search${isFixedSearchBarDisplay}${isScrolled}`;
        };
        const brandingOverlayStyles = () => {
            const isScrolled = this.state.scrolled && this.state.enableSticky ? ' -scrolled' : '';
            return `branding__overlay${isScrolled}`;
        };
        const loadingStyle = {
            display: 'none',
            zIndex: 11111,
            opacity: 0,
        };

        const {
            enablePodRedirection,
            corporate,
            returnToCurrentServiceAfterRegistration,
            returnUrlQueryString,
            registrationSource,
            showNavigationMenu,
            showSearch,
            isTransparent,
        } = this.props;

        const navigation =
            showNavigationMenu === false ? null : (
                <Navigation
                    stickySearchBarDisplay={this.state.stickySearchBarDisplay}
                    isTransparent={isTransparent}
                    updateMenuActive={this.updateMenuSelected}
                    authenticated={this.state.authenticated}
                    pod={this.state.pod}
                    updateFixedSearchBarDisplay={this.updateFixedSearchBarDisplay}
                    scrolled={this.state.scrolled}
                    isCorporate={corporate}
                    isMobile={this.state.screenWidth < 1150}
                    isSmallDesktop={this.state.screenWidth < 1300}
                    enableIndustryKPIs={config.enableIndustryKPIs === 'true'}
                    hasFreemiumRole={this.state.hasFreemiumRole}
                />
            );

        const logo = <Logo scrolled={this.state.scrolled} isTransparent={isTransparent} authenticated={this.state.authenticated} pod={this.state.pod} />;

        const search = (
            <div className={brandingSearchStyles()} ref={this.setsearchBarRef}>
                {showSearch === false ? null : (
                    <SearchBar
                        authenticated={this.state.authenticated}
                        firstName={this.state.firstName}
                        pod={this.state.pod}
                        enablePodRedirection={enablePodRedirection}
                        scrolled={this.state.scrolled}
                    />
                )}
            </div>
        );

        const userLogin =
            corporate || !this.state.authenticated ? (
                <UserLogin
                    scrolled={this.state.scrolled}
                    isTransparent={isTransparent}
                    enablePodRedirection={this.state.enablePodRedirection}
                    returnToCurrentServiceAfterRegistration={returnToCurrentServiceAfterRegistration}
                    returnUrlQueryString={returnUrlQueryString}
                    registrationSource={registrationSource}
                    pod={this.state.pod}
                    authenticated={this.state.authenticated}
                    corporate={corporate}
                />
            ) : null;

        const userMenuLoggedIn =
            !corporate && this.state.authenticated ? (
                <UserMenu
                    authenticated={this.state.authenticated}
                    isTransparent={isTransparent}
                    enablePodRedirection={this.state.enablePodRedirection}
                    isIpUser={this.state.isIpUser}
                    userEmail={this.state.email}
                    canReferAColleague={this.state.referAColleague}
                    scrolled={this.state.scrolled}
                />
            ) : null;

        const userFavoritesLoggedIn =
            this.state.authenticated ? (
                <UserFavorites
                    authenticated={this.state.authenticated}
                    isTransparent={isTransparent}
                    scrolled={this.state.scrolled}
                />
            ) : null;

        const calendar = config.enableCalendarWidget ? (
            <CalendarWidget authenticated={this.state.authenticated} isTransparent={isTransparent} scrolled={this.state.scrolled} />
        ) : null;

        const userCompanyLogin = !this.state.authenticated ? (
            <UserCompanyLogin
                scrolled={this.state.scrolled}
                isTransparent={isTransparent}
                enablePodRedirection={this.state.enablePodRedirection}
                returnToCurrentServiceAfterRegistration={returnToCurrentServiceAfterRegistration}
                returnUrlQueryString={returnUrlQueryString}
                registrationSource={registrationSource}
            />
        ) : null;

        // PROMO ICON - COVID/RMN/FUTURE+
        const promotionalIcon = this.state.authenticated ? (
            <UserPromoMenu
                authenticated={this.state.authenticated}
                scrolled={this.state.scrolled}
            />
        ) : null;

        const mobileBranding =
            this.state.screenWidth < 1150 ? (
                <MobileMenu
                    authenticated={this.state.authenticated}
                    isIpUser={this.state.isIpUser}
                    isTransparent={isTransparent}
                    pod={this.state.pod}
                    enablePodRedirection={enablePodRedirection}
                    userEmail={this.state.email}
                    returnToCurrentServiceAfterRegistration={returnToCurrentServiceAfterRegistration}
                    returnUrlQueryString={returnUrlQueryString}
                    registrationSource={registrationSource}
                    enableIndustryKPIs={config.enableIndustryKPIs === 'true'}
                    hasFreemiumRole={this.state.hasFreemiumRole}
                />
            ) : null;

        const desktopBranding =
            this.state.screenWidth >= 1150 ? (
                <div className="branding__upper">
                    {logo}
                    {search}
                    <div className={`branding__user ${this.state.scrolled ? '-scrolled' : ''}`}>
                        {promotionalIcon}
                        {userMenuLoggedIn}
                        {userFavoritesLoggedIn}
                        {userLogin}
                        {calendar}
                        {userCompanyLogin}
                    </div>
                </div>
            ) : null;

        const overlay = this.state.menuActive ? <div className={brandingOverlayStyles()} /> : null;

        return (
            <>
             {freemiumOnboardingPopup()/* this needs to be placed above everything else, so that other z-indexes do not interfere with the modal */}
            <div className={brandingStyles()} style={loadingStyle} onScroll={this.handleScroll}>
                {/* This is the font for both branding and the subscription plugin */}
                <link href={brandingServiceCssUrl} media="screen, projection" rel="preload stylesheet prefetch" type="text/css" as="style" />
                <link rel="preload stylesheet prefetch" href={config.fontCssUrl} as="style" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                {requireIEDeprecationBannerSection}
                {mobileBranding}
                {desktopBranding}
                {navigation}
                {interComSection}
                {overlay}

            </div>
            </>
        );
    }
}

module.exports = Branding;
