import React, { Component } from 'react';
import Menu from './Menu/Menu';
import Loading from '../Loading/Loading';
import CalendarIcon from '../../theme/svgs/calendar';
import Cookies from 'js-cookie';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { gql } from 'apollo-boost';
import config from '../../config';
import fetch from 'isomorphic-unfetch';
import './CalendarWidget.scss';

interface ICalendarProps {
    authenticated: boolean;
    isTransparent: boolean;
    scrolled?: boolean;
}

interface ICalendarState {
    open: boolean;
    userMenuWrapperRef: any;
    notification: boolean;
    latestAssetData: any[];
}

export default class CalendarWidget extends Component<ICalendarProps, ICalendarState> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userMenuWrapperRef: '',
            notification: false,
            latestAssetData: [],
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setUserMenuWrapperRef = this.setUserMenuWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const cookieId = config.pod !== 'NAX' ? 'em_unn_ts_pro' : 'em_unn_ts';

        // check for the latest asset data cookie timestamp
        const timeStampCookie = Cookies.get(cookieId);
        if (timeStampCookie === undefined) {
            // if you dont see one - turn notification on and dont do a gql fetch
            this.setState({
                notification: true,
            });

            return;
        }

        let latestAssetData = [];
        let hasNewAssetData = false;

        const client = new ApolloClient({
            link: new HttpLink({
              uri: config.noteworthyContentApiUrl,
              credentials: 'same-origin',
              fetch,
            }),
            cache: new InMemoryCache(),
        });

        const NEWEST_ASSETS = gql`
        query {
            noteworthyAssets {
                latestAssets {
                    firstAvailableDateTimeUtc,
                }
            }
        }
        `;

        client
        .query({
            query: NEWEST_ASSETS,
        })
        .then((result) => {
            latestAssetData = result.data.noteworthyAssets.latestAssets;
            let i;
            for (i = 0; i < latestAssetData.length; i++) {
                if (new Date(latestAssetData[i].firstAvailableDateTimeUtc) > new Date(timeStampCookie)) {
                    hasNewAssetData = true;
                }
            }
            this.setState({
                notification: hasNewAssetData,
            });
        });
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setUserMenuWrapperRef(node) {
        this.setState({
            userMenuWrapperRef: node,
        });
    }

    private toggleMenu() {
        this.setState({
            open: !this.state.open,
            notification: false,
        });
    }

    private closeMenu() {
        this.setState({ open: false });
    }

    private handleClickOutside(event) {
        if (this.state.open) {
            if (this.state.userMenuWrapperRef && !this.state.userMenuWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    public render() {

        const {isTransparent, scrolled } = this.props;

        const buttonStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';

            return `branding-calendar__button${isT}${isS}`;
        };

        const iconStyles = () => {
            const isT = isTransparent ? ' -transparent' : '';
            const isS = scrolled ? ' -scrolled' : '';

            return `branding-calendar__icon${isT}${isS}`;
        };
        const brandingUserStyles = () => {
            const isS = scrolled ? ' -scrolled' : '';
            return `branding-calendar${isS}`;
        };

        return (
            <div className={brandingUserStyles()}
                 ref={this.setUserMenuWrapperRef}>
                <div className={buttonStyles()}
                     onClick={this.toggleMenu}
                     data-analytics="Branding-Tools-Calendar-Toggle">
                    {
                        this.state.notification ?
                            <div className="branding-calendar__notification" />
                            : null
                    }
                    <div className={`${iconStyles()} `}>
                        <CalendarIcon />
                        <use xlinkHref={`#icon-calendar`} xmlnsXlink="http://www.w3.org/1999/xlink" />
                    </div>
                </div>
                {
                    this.state.open ?
                    <React.Suspense fallback={<Loading />}>
                        <Menu
                            active={this.state.open}
                            title={''}
                        />
                    </React.Suspense> : null
                }
            </div>
        );
    }
}
