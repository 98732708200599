import React, { Component } from 'react';
import Menu from './Menu/Menu';
import AIIcon from '../../theme/svgs/ai';
import './UserPromoMenu.scss';
import config from 'config';

/* tslint:disable */

interface IUserPromoMenuProps {
    authenticated: boolean;
    scrolled?: boolean;
}

interface IUserPromoMenuState {
    open: boolean;
    userMenuWrapperRef: any;
}

export default class UserPromoMenu extends Component<IUserPromoMenuProps, IUserPromoMenuState> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userMenuWrapperRef: '',
        };
        this.closeMenu = this.closeMenu.bind(this);
        this.setUserMenuWrapperRef = this.setUserMenuWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.goToLink = this.goToLink.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.mouseLeave();

        const hideUserPromoModal = document.cookie.indexOf('hide_user_promo_modal=') > -1;

        if (!hideUserPromoModal) {
            this.setState({ open: true });
            document.cookie = 'hide_user_promo_modal=true; path=/; domain=.emarketer.com; expires=0;';
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private setUserMenuWrapperRef(node) {
        this.setState({
            userMenuWrapperRef: node,
        });
    }

    private closeMenu() {
        this.setState({ open: false });
    }

    private mouseOver() {
        this.setState({ open: true });
    }

    private mouseLeave() {
        setTimeout(() => {
            this.setState({ open: false });
        }, 15000);
    }

    private handleClickOutside(event) {
        if (this.state.open) {
            if (this.state.userMenuWrapperRef && !this.state.userMenuWrapperRef.contains(event.target)) {
                this.closeMenu();
            } else {
                return false;
            }
        }
    }

    private goToLink() {
        location.href = `${config.researchTopicsServiceUrl}/category/AI%20in%20Marketing`;
    }

    public render() {

        const { scrolled, authenticated } = this.props;

        const brandingUserStyles = () => {
            const isS = scrolled ? ' -scrolled' : '';
            const isA = authenticated ? ' -offset' : '';
            return `branding-user-promo-menu${isS}${isA}`;
        };

        return (
            <div className={brandingUserStyles()}
                 onClick={this.goToLink}
                 ref={this.setUserMenuWrapperRef}>
                <div className={`branding-user-promo-menu__button`}
                     onMouseOver={this.mouseOver}
                     onMouseLeave={this.mouseLeave}
                     data-analytics="Branding-Promo-AI">
                        <AIIcon />
                </div>
                <Menu active={this.state.open} />
            </div>
        );
    }
}